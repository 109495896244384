import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormDialogComponent } from 'src/app/UI/form-dialog/form-dialog.component';
import { FormService } from 'src/app/services/form.service';
import * as langEn from './locale_en.json'
import * as langRu from './locale_ru.json'
import { Translate } from 'src/app/services/form.service';

@Component({
  selector: 'app-investment-adviser',
  templateUrl: './investment-adviser.component.html',
  styleUrls: ['./investment-adviser.component.scss'],
  animations:[
    trigger("line", [
      state('start', style({width:'0%'})),
      state('end', style({width:'100%'})),

      transition('start <=> end', animate('.3s'))
    ]),
    trigger('slider', [
      state('start', style({ transform: '*' })),
      state('midle', style({ transform: 'translateX(-450px)' })),
      state('end', style({ transform: 'translateX(-900px)' })),
      state('end2', style({ transform: 'translateX(-1350px)' })),
  
      transition('midle <=> end', animate('1.3s')),
      transition('midle <=> start', animate('1s')),
      transition('end <=> start', animate('1s')),
      transition('end <=> end2', animate('1s')),
      transition('midle <=> end2', animate('1s')),
      transition('start <=> end2', animate('1s')),
    ]),
  ]
})
export class InvestmentAdviserComponent {
  lineState = 'start'
  lineState2 = 'start'
  
  overNav(num:number){
    if(num===1){
      this.lineState = 'end'
    }else{
      this.lineState2 = 'end'
    }
    
  } 
  leaveNav(num:number){
    if(num===1){
      this.lineState = 'start'
    }else{
      this.lineState2 = 'start'
    }
  } 

  sliderState = 'start'
  sliderCounter:number = 0 
  sliderInterval:any
  
  curLang = "en"
  en = langEn
  ru = langRu
  translates:Translate = {
    "en": this.en.en,
    "ru": this.ru.ru,
  }
  constructor(private formService:FormService, public dialog: MatDialog){
    formService.curLang.subscribe(res=> this.curLang = res!)
  }

  openDialog(){
    const dialogRef = this.dialog.open(FormDialogComponent);
  }

  ngOnInit(): void {
    this.sliderInterval = setInterval(()=>{
      this.sliderMove()
    },3000)
  }

  sliderMove():void{
    this.sliderCounter++
    if(this.sliderCounter===1){
      this.sliderState = 'midle'
    }
    if(this.sliderCounter===2){
      this.sliderState = 'end'
    }
    if(this.sliderCounter===3){
      this.sliderState = 'end2'
    }
    if(this.sliderCounter===4){
      this.sliderState = 'start'
      this.sliderCounter = 0
    }
  }
  changeSlide(num:number){
    this.sliderCounter = num; 
    this.sliderMove()
    clearInterval(this.sliderInterval)
    this.sliderInterval = setInterval(()=>{
      this.sliderMove()
    },3000)
  }

  info1 = false;
  info2 = false;
  info3 = false;
  
  showInfo1(){
    this.info1 = !this.info1
  }
  showInfo2(){
    this.info2 = !this.info2
  }
  showInfo3(){
    this.info3 = !this.info3
  }
}
