<div class="container">
  <div class="left">
    <h2>{{translates[curLang].text1}}</h2>
    <p>{{translates[curLang].text2}}</p>
    <div class="buttons">
      <button class="green-button" style="margin-right: 10px;" (click)="openDialog()">{{translates[curLang].text3}}</button>
      <button class="green-button tranparent-btn" (click)="openDialog()">{{translates[curLang].text4}}</button>
    </div>

  </div>
  <div class="right">
    <img src="/assets/question.svg" alt="">
  </div>
</div>
