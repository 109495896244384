
<app-header style="z-index: 10;"></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>

<div class="quickMenu">
    <div class="menu" id="menu">
        <div class="menu-item" (click)="goTgChat()">
            <img src="../assets/chat.svg" alt="">
            <span>{{translates[curLang].text2}}</span>
        </div>
        <div class="menu-item" (click)="goWhatsApp()">
            <img src="../assets/whatsApp.svg" alt="">
            <span>WhatsApp</span>
        </div>
        <div class="menu-item" (click)="goTelegram()">
            <img src="../assets/telegram.svg" alt="">
            <span>Our Telegram Group</span>
        </div>
        <div class="menu-item" (click)="openDialog()">
            <img src="../assets/header/phone.svg" alt="">
            <span>{{translates[curLang].text1}}</span>
        </div>
    </div>
    <div class="opener" (click)="openMenu()">
        <img src="../assets/message.svg" *ngIf="!menuIsOpen" alt="">
        <img src="../assets/x.svg" *ngIf="menuIsOpen" alt="">
    </div>
</div>