<div class="container">
  <div class="imgBlock">
    <img src="{{img}}" alt="">
  </div>
  <div class="infoBlock">
    <h1>Ключевые показатели фонда</h1>
    <div class="infoCards">
      <div class="infoCard">
        <p> от
          <span style="font-weight: bold;">{{start_summ}}₽</span>
        </p>
        <p>Стартовая сумма</p>
      </div>
      <div class="infoCard">
        <p> от
          <span style="font-weight: bold;">{{year}} года</span>
        </p>
        <p>Рекомендуемый срок</p>
      </div>
    </div>
    <div class="infoCards">
      <div class="infoCard">
        <p>{{percent}}</p>
        <p>Целевая доходность</p>
      </div>
      <div class="infoCard">
        <img src="" alt="">
        <p>Уровень риска</p>
      </div>
    </div>
  </div>
</div>
