<div class="header-wrapepr" id="mainMenu">
    <header>
        <div class="header-wrapper">
            <div class="left-nav">
                <div class="logo">
                    <img src="../../../assets/logo2.png" class="logo-img" width="55px" height="55px" alt="logo"
                        style="cursor: pointer;" [routerLink]="'/home'">
                    <img src="../../../assets/header/menu.svg" (click)="changeDop()" class="menu-img" alt="logo"
                        style="margin-left: 10px; cursor: pointer;">
                </div>
                <div class="contact">
                    <div class="item">
                        <!-- <img src="../../../assets/telegram.svg" (click)="goTelegram()" style="cursor: pointer;"  alt=""> -->
                        <img src="../../../assets/ru.png" *ngIf="curLang==='ru'" class="flag" alt="">
                        <img src="../../../assets/en.png" *ngIf="curLang==='en'" class="flag" alt="">
                    </div>
                    <div class="item" style="margin-left: 5px;">
                        <!-- <img src="../../../assets/header/phone.svg" alt=""> -->
                        <select (change)="changeLang($event)" class="main-lang"
                            style="background: transparent; border:none;">
                            <option style="color: #666; cursor: pointer;" value="ru" [selected]="curLang === 'ru'">
                                Russian</option>
                            <option style="color: #666; cursor: pointer;" value="en" [selected]="curLang === 'en'">
                                English</option>
                        </select>
                    </div>
                    <div class="item phones">
                        <span><a href="tel:+79262396683">+79262396683</a></span>
                        <span><a href="tel:+79295058582">+79295058582</a></span>
                    </div>
                    <div class="contacts">
                        <img src="../../../assets/telegram.svg" (click)="goTelegram()" alt="">
                        <img src="../../../assets/whatsApp.svg" (click)="goWhatsApp()" alt="">
                    </div>
                </div>
            </div>
            
            <div class="center-nav">
                <div class="center-item" (click)="openMenu1()">
                    <span [ngClass]="{'active-text':menu1}">{{translates[curLang].text1}}</span>
                    <img [ngClass]="{'active-img':menu1}" src="../../../assets/header/arrow.svg" alt="">
                </div>
                <div class="center-item" (click)="openMenu2()">
                    <span [ngClass]="{'active-text':menu2}">{{translates[curLang].text2}}</span>
                    <img [ngClass]="{'active-img':menu2}" src="../../../assets/header/arrow.svg" alt="">
                </div>
                <div class="center-item" (click)="openMenu3()">
                    <span [ngClass]="{'active-text':menu3}">{{translates[curLang].text3}}</span>
                    <img [ngClass]="{'active-img':menu3}" src="../../../assets/header/arrow.svg" alt="">
                </div>
                <div class="center-item" (click)="openMenu4()">
                    <span [ngClass]="{'active-text':menu4}">{{translates[curLang].text4}}</span>
                    <img [ngClass]="{'active-img':menu4}" src="../../../assets/header/arrow.svg" alt="">
                </div>
                <div class="center-item" (click)="openMenu5()">
                    <span [ngClass]="{'active-text':menu5}">{{translates[curLang].text5}}</span>
                    <img [ngClass]="{'active-img':menu5}" src="../../../assets/header/arrow.svg" alt="">
                </div>

            </div>
            <div class="right-nav">
                <div class="buttons">
                    <div class="btn-item">
                        <button (click)="pay()" class="green-button tranparent-btn"
                            style="padding:0 10px; margin: 0; width: fit-content;">
                            {{translates[curLang].text6}}
                        </button>
                    </div>
                    <div class="btn-item">
                        <button (click)="pay()" class="green-button"
                            style="padding:0 10px; margin: 0; width: fit-content;">
                            {{translates[curLang].text7}}
                        </button>
                    </div>
                    <div class="btn-item last" (click)="pay()">
                        <img src="../../../assets/header/door.svg" alt="">
                        <span class="insert">{{translates[curLang].text8}}</span>
                    </div>
                </div>
            </div>
        </div>

    </header>
    <div class="menu" id="menu1" [@menu]="menuState1">
        <div class="content-wrapper">
            <div class="nav-container">
                <div class="nav-item">
                    <span [routerLink]="'/trading/trading-account'"
                        (click)="openMenu1()">{{translates[curLang].text9}}</span>
                </div>
                <div class="nav-item">
                    <span [routerLink]="'/trading/individual-investment-account'"
                        (click)="openMenu1()">{{translates[curLang].text10}}</span>
                </div>
                <div class="nav-item">
                    <span [routerLink]="'/services/margin-trading'" style="margin-top: 40px;"
                        (click)="openMenu1()">{{translates[curLang].text11}}</span>
                </div>
            </div>
            <div class="block-container">

            </div>
        </div>
    </div>

    <div class="menu" id="menu2" [@menu]="menuState2">
        <div class="content-wrapper">
            <div class="nav-container">
                <div class="nav-item">
                    <span [routerLink]="'/solutions/trust-strategies'"
                        (click)="openMenu2()">{{translates[curLang].text12}}</span>
                    <span class="green" [routerLink]="'/solutions/trust-strategies/monetary'"
                        (click)="openMenu2()">{{translates[curLang].text13}}</span>
                </div>
                <div class="nav-item">
                    <span [routerLink]="'/solutions/du-iis'" (click)="openMenu2()">{{translates[curLang].text14}}</span>
                </div>
            </div>
            <div class="block-container">

            </div>
        </div>
    </div>
    <div class="menu" id="menu3" [@menu]="menuState3">
        <div class="content-wrapper">
            <div class="nav-container">
                <div class="nav-item">
                    <span [routerLink]="'/trust/individual-trust-strategy'"
                        (click)="openMenu3()">{{translates[curLang].text15}}</span>
                </div>
                <div class="nav-item">
                    <span [routerLink]="'/trust/investment-adviser'"
                        (click)="openMenu3()">{{translates[curLang].text16}}</span>
                </div>
            </div>
            <div class="block-container">

            </div>
        </div>
    </div>
    <div class="menu" id="menu4" [@menu]="menuState4">
        <div class="content-wrapper">
            <div class="nav-container">
                <div class="nav-item">
                    <span [routerLink]="'/analytics/instruments'"
                        (click)="openMenu4()">{{translates[curLang].text17}}</span>
                </div>

            </div>
            <div class="block-container">

            </div>
        </div>
    </div>
    <div class="menu" id="menu5" [@menu]="menuState5">
        <div class="content-wrapper">
            <div class="nav-container">
                <div class="nav-item">
                    <span>{{translates[curLang].text18}}</span>
                    <span class="green" [routerLink]="'/about-us/about-us'"
                        (click)="openMenu5()">{{translates[curLang].text19}}</span>
                    <span class="green" [routerLink]="'/about-us/work-with-us'"
                        (click)="openMenu5()">{{translates[curLang].text20}}</span>
                    <span class="green" [routerLink]="'/about-us/contacts'"
                        (click)="openMenu5()">{{translates[curLang].text21}}</span>
                </div>
                <div class="nav-item">
                    <span>{{translates[curLang].text48}}</span>
                    <span class="green" [routerLink]="'/documents/information/general-documents'"
                        (click)="openMenu5()">{{translates[curLang].text22}}</span>
                    <span class="green" [routerLink]="'/documents/information/compliance'"
                        (click)="openMenu5()">{{translates[curLang].text23}}</span>
                    <span class="green" [routerLink]="'/education-block/faq/general-issues'"
                        (click)="openMenu5()">{{translates[curLang].text24}}</span>
                </div>
            </div>
            <div class="block-container">

            </div>
        </div>
    </div>

</div>

<div class="dop-menu" [@dop-menu]="dopState">
    <div class="dop-header">
        <div class="header-wrapper">
            <div class="left-nav">
                <div class="logo">
                    <img src="../../../assets/logo2.png" class="logo-img" (click)="changeDop()" width="55px"
                        height="55px" alt="logo" style="cursor: pointer;" [routerLink]="'/home'">
                    <img src="../../../assets/header/close.svg" (click)="changeDop()" class="menu-img" alt="logo"
                        style="margin-left: 10px;">
                </div>
                <div class="contact">
                    <div class="item">
                        <img src="../../../assets/header/geo.svg" alt="">
                    </div>
                    <div class="item">
                        <img src="../../../assets/header/phone.svg" alt="">
                    </div>
                    <div class="item">
                        <span style="color: #fff; margin-left: 10px;">8(800)200-53-02</span>
                    </div>
                </div>
            </div>

            <div class="right-nav">
                <div class="buttons">
                    <div class="btn-item">
                        <button (click)="pay()" class="green-button tranparent-btn"
                            style="padding:0 10px; margin: 0; width: fit-content;">
                            {{translates[curLang].text25}}
                        </button>
                    </div>
                    <div class="btn-item">
                        <button (click)="pay()" class="green-button"
                            style="padding:0 10px; margin: 0; width: fit-content;">
                            {{translates[curLang].text26}}
                        </button>
                    </div>
                    <div class="btn-item" style="display: flex; align-items: center;">
                        <select (change)="changeLang($event)" class="main-lang"
                            style="background: transparent; border:none; font-size: 1rem; color: #fff;">
                            <option style="color: #666;" value="ru" [selected]="curLang === 'ru'">Russian</option>
                            <option style="color: #666;" value="en" [selected]="curLang === 'en'">English</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content-wrapper">
        <div class="dop-content">
            <div class="dop-section">
                <div class="sec-title" (click)="openDop1()">
                    <h2>{{translates[curLang].text27}}</h2>
                    <img src="../../../assets/header/white-arrow.svg" [ngClass]="{'rotate':dopMenu1}" alt="">
                </div>

                <div class="list" *ngIf="dopMenu1">
                    <p [routerLink]="'/trading/trading-account'" (click)="changeDop()">{{translates[curLang].text28}}
                    </p>
                    <p [routerLink]="'/trading/individual-investment-account'" (click)="changeDop()">
                        {{translates[curLang].text29}}</p>
                    <p [routerLink]="'/services/margin-trading'" (click)="changeDop()">{{translates[curLang].text30}}
                    </p>
                </div>
            </div>

            <div class="dop-section">
                <div class="sec-title" (click)="openDop2()">
                    <h2>{{translates[curLang].text31}}</h2>
                    <img src="../../../assets/header/white-arrow.svg" [ngClass]="{'rotate':dopMenu2}" alt="">
                </div>

                <div class="list" *ngIf="dopMenu2">
                    <p [routerLink]="'/solutions/trust-strategies'" (click)="changeDop()">{{translates[curLang].text32}}
                    </p>
                    <p [routerLink]="'/solutions/trust-strategies/monetary'" (click)="changeDop()">
                        {{translates[curLang].text33}}</p>
                    <p [routerLink]="'/solutions/du-iis'" (click)="changeDop()">{{translates[curLang].text34}}</p>
                </div>
            </div>
            <div class="dop-section">
                <div class="sec-title" (click)="openDop3()">
                    <h2>{{translates[curLang].text35}}</h2>
                    <img src="../../../assets/header/white-arrow.svg" [ngClass]="{'rotate':dopMenu3}" alt="">
                </div>

                <div class="list" *ngIf="dopMenu3">
                    <p [routerLink]="'/trust/individual-trust-strategy'" (click)="changeDop()">
                        {{translates[curLang].text36}}</p>
                    <p [routerLink]="'/trust/investment-adviser'" (click)="changeDop()">{{translates[curLang].text37}}
                    </p>
                </div>
            </div>
            <div class="dop-section">
                <div class="sec-title" (click)="openDop4()">
                    <h2>{{translates[curLang].text38}}</h2>
                    <img src="../../../assets/header/white-arrow.svg" [ngClass]="{'rotate':dopMenu4}" alt="">
                </div>

                <div class="list" *ngIf="dopMenu4">
                    <p [routerLink]="'/analytics/instruments'" (click)="changeDop()">{{translates[curLang].text39}}</p>
                </div>
            </div>
            <div class="dop-section">
                <div class="sec-title" (click)="openDop5()">
                    <h2>{{translates[curLang].text40}}</h2>
                    <img src="../../../assets/header/white-arrow.svg" [ngClass]="{'rotate':dopMenu5}" alt="">
                </div>

                <div class="list" *ngIf="dopMenu5">
                    <p class="green" [routerLink]="'/about-us/about-us'" (click)="changeDop()">
                        {{translates[curLang].text41}}</p>
                    <p class="green" [routerLink]="'/about-us/work-with-us'" (click)="changeDop()">
                        {{translates[curLang].text42}}</p>
                    <p class="green" [routerLink]="'/about-us/contacts'" (click)="changeDop()">
                        {{translates[curLang].text43}}</p>
                </div>
            </div>
            <div class="dop-section">
                <div class="sec-title" (click)="openDop6()">
                    <h2>{{translates[curLang].text44}}</h2>
                    <img src="../../../assets/header/white-arrow.svg" [ngClass]="{'rotate':dopMenu6}" alt="">
                </div>

                <div class="list" *ngIf="dopMenu6">
                    <p class="green" [routerLink]="'/documents/information/general-documents'" (click)="changeDop()">
                        {{translates[curLang].text45}}</p>
                    <p class="green" [routerLink]="'/documents/information/compliance'" (click)="changeDop()">
                        {{translates[curLang].text46}}</p>
                    <p class="green" [routerLink]="'/education-block/faq/general-issues'" (click)="changeDop()">
                        {{translates[curLang].text47}}</p>
                </div>
            </div>
        </div>
    </div>

    <div class="dop-footer">

    </div>
</div>