import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FormService, form } from 'src/app/services/form.service';
import * as langEn from './locale_en.json'
import * as langRu from './locale_ru.json'
import { Translate } from 'src/app/services/form.service';

@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.scss']
})
export class FormDialogComponent {
  infoForm = new FormGroup({
    name : new FormControl('', [Validators.required, Validators.minLength(5)]),
    phone : new FormControl('', [Validators.required, Validators.minLength(12)])
})

curLang = "en"
en = langEn
ru = langRu
translates:Translate = {
  "en": this.en.en,
  "ru": this.ru.ru,
}
constructor(private formService:FormService, public dialog: MatDialog){
  formService.curLang.subscribe(res=> this.curLang = res!)
}
  sendForm(){
    let tst:form = {
      name:this.infoForm.value.name!,
      phone:this.infoForm.value.phone!
    }
    //console.log(tst);
    
    this.formService.sendForm(tst).subscribe(res=>{
      //console.log(res);
      this.dialog.closeAll()
    });
  }
}
