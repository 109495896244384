import { Component } from '@angular/core';
import * as langEn from './locale_en.json'
import * as langRu from './locale_ru.json'
import { FormService, Translate } from 'src/app/services/form.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent {
  openPdf1(){
    window.open('/assets/pdfs/ДОГОВОРНАВЕДЕНИЕИНДИВИДУАЛЬНОИНВЕСТИЦИОННОГОСЧЕТА.pdf','_blank') 
  }
  openPdf2(){
    window.open('/assets/pdfs/Договор об оказании брокерских услуг.pdf','_blank') 
  }

  curLang = "en"
  en = langEn
  ru = langRu
  translates:Translate = {
    "en": this.en.en,
    "ru": this.ru.ru,
  }
  constructor(private formService:FormService){
    formService.curLang.subscribe(res=> this.curLang = res!)
  }
}
