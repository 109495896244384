<div class="trade-nav">
    <div class="content-wrapper" style="display: flex;">
        <div class="nav-item" (mouseover)="overNav(1)" (mouseleave)="leaveNav(1)">
            <p class="nav-text" [routerLink]="'/solutions/trust-strategies'">{{translates[curLang].text1}}</p>
            <div class="bottom-line" [@line]="lineState"></div>
        </div>
        <div class="nav-item"  (mouseover)="overNav(2)" (mouseleave)="leaveNav(2)">
            <p class="nav-text" [routerLink]="'/solutions/du-iis'">{{translates[curLang].text2}}</p>
            <div class="bottom-line" [@line]="lineState2"></div>
        </div>
    </div>
</div>
<app-main-title 
    [img]="'/trust-strategies/machine.jpg'" 
    [title]="translates[curLang].text3" 
    [text]="translates[curLang].text4"
    [btnText]="translates[curLang].text5"
    [showControls]="true"
    [controlText1]="translates[curLang].text6"
    [controlText2]="translates[curLang].text7"
    [textColor]="'#000'"
    [link1]="'/solutions/trust-strategies'"
    [link2]="'/solutions/trust-strategies/monetary'"
></app-main-title>
<app-reasons [title1]="translates[curLang].text8" [img1]="'/trust-strategies/1.svg'"
    [title2]="translates[curLang].text9" [img2]="'/trust-strategies/2.svg'"
    [title3]="translates[curLang].text10" [img3]="'/trust-strategies/3.svg'"
></app-reasons>
<div class="img-text-block">
    <div class="content-wrapper" style="display: flex;">
        <div class="img-container">
            <img src="../../../assets/trust-strategies/gears.png" alt="">
        </div>
        <div class="info-container">
            <h2>{{translates[curLang].text11}}</h2>
            <p>{{translates[curLang].text12}}</p>
        </div>
    </div>
</div>
<app-how-to-investor></app-how-to-investor>
<div class="instruction">
    <div class="content-wrapper">
        <div class="intruct-container">
            <div class="intruct-item" [ngClass]="{'active':info1}" (click)="showInfo1()">
                <div class="text" >
                    <span>{{translates[curLang].text13}}</span>
                    <p>{{translates[curLang].text14}}</p>
                </div>
                <div class="img-container">
                    <img src="../../../assets/header/arrow.svg"  alt="">
                </div>
            </div>

            <div class="intruct-item" [ngClass]="{'active':info2}" (click)="showInfo2()">
                <div class="text">
                    <span>{{translates[curLang].text15}}</span>
                    <p>{{translates[curLang].text16}}</p>
                </div>
                <div class="img-container">
                    <img src="../../../assets/header/arrow.svg"  alt="">
                </div>
            </div>

            <div class="intruct-item" [ngClass]="{'active':info3}" (click)="showInfo3()">
                <div class="text">
                    <span>{{translates[curLang].text17}}</span>
                    <p>- {{translates[curLang].text18}}</p>
                </div>
                <div class="img-container">
                    <img src="../../../assets/header/arrow.svg"  alt="">
                </div>
            </div>
        </div>
    </div>
</div>
<app-question-block></app-question-block>
<app-documents></app-documents>