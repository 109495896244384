import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './UI/header/header.component';
import { FooterComponent } from './UI/footer/footer.component';
import { MainComponent } from './main/main.component';
import { TradingAccountComponent } from './trading/trading-account/trading-account.component';
import { IndividualInvestmentAccountComponent } from './trading/individual-investment-account/individual-investment-account.component';
import { ModelPortfoliosComponent } from './trading/model-portfolios/model-portfolios.component';
import { TrustStrategiesComponent } from './solutions/trust-strategies/trust-strategies.component';
import { MonetaryComponent } from './solutions/trust-strategies/monetary/monetary.component';
import { DuIisComponent } from './solutions/du-iis/du-iis.component';
import { IndividualTrustStrategyComponent } from './trust/individual-trust-strategy/individual-trust-strategy.component';
import { InvestmentAdviserComponent } from './trust/investment-adviser/investment-adviser.component';
import { InvestIdeasComponent } from './analytics/invest-ideas/invest-ideas.component';
import { InstrumentsComponent } from './analytics/instruments/instruments.component';
import { AboutUsComponent } from './about-us/about-us/about-us.component';
import { NewsComponent } from './about-us/news/news.component';
import { LicenseComponent } from './about-us/license/license.component';
import { ContactsComponent } from './about-us/contacts/contacts.component';
import { WorkWithUsComponent } from './about-us/work-with-us/work-with-us.component';
import { HowToInvestorComponent } from './helper-components/how-to-investor/how-to-investor.component';
import { QuestionBlockComponent } from './helper-components/question-block/question-block.component';
import { DocumentsComponent } from './helper-components/documents/documents.component';
import { RoutingBlockComponent } from './helper-components/routing-block/routing-block.component';
import { PortfelsComponent } from './helper-components/portfels/portfels.component';
import { SeeAlsoFundsComponent } from './helper-components/see-also-funds/see-also-funds.component';
import { KeyFundIndicatorsComponent } from './helper-components/key-fund-indicators/key-fund-indicators.component';
import { CostDynamicComponent } from './helper-components/cost-dynamic/cost-dynamic.component';
import { StrategiesComponent } from './helper-components/strategies/strategies.component';
import { MainTitleComponent } from './helper-components/main-title/main-title.component';
import { ConsultationModalComponent } from './helper-components/consultation-modal/consultation-modal.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReasonsComponent } from './helper-components/reasons/reasons.component';

import {HttpClientModule} from "@angular/common/http";
import { FormDialogComponent } from './UI/form-dialog/form-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { ContactComponent } from './about-us/contact/contact.component';
import { GeneralDocumentsComponent } from './documents/general-documents/general-documents.component';
import { ComplianceComponent } from './documents/compliance/compliance.component';
import { GeneralIssuesComponent } from './documents/general-issues/general-issues.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    MainComponent,
    TradingAccountComponent,
    IndividualInvestmentAccountComponent,
    ModelPortfoliosComponent,
    TrustStrategiesComponent,
    MonetaryComponent,
    DuIisComponent,
    IndividualTrustStrategyComponent,
    InvestmentAdviserComponent,
    InvestIdeasComponent,
    InstrumentsComponent,
    AboutUsComponent,
    NewsComponent,
    LicenseComponent,
    ContactsComponent,
    WorkWithUsComponent,
    HowToInvestorComponent,
    QuestionBlockComponent,
    DocumentsComponent,
    RoutingBlockComponent,
    PortfelsComponent,
    SeeAlsoFundsComponent,
    KeyFundIndicatorsComponent,
    CostDynamicComponent,
    StrategiesComponent,
    MainTitleComponent,
    ConsultationModalComponent,
    ReasonsComponent,
    FormDialogComponent,
    ContactComponent,
    GeneralDocumentsComponent,
    ComplianceComponent,
    GeneralIssuesComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatDialogModule,
    MatInputModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
