import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormService, form } from '../services/form.service';
import { MatDialog } from '@angular/material/dialog';
import { FormDialogComponent } from '../UI/form-dialog/form-dialog.component';
import * as langEn from './locale_en.json'
import * as langRu from './locale_ru.json'
import { Translate } from 'src/app/services/form.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [
    trigger('slider', [
      state('start', style({ transform: '*' })),
      state('midle', style({ transform: 'translateX(-20vw)' })),
      state('end', style({ transform: 'translateX(-40vw)' })),

      state('mob1', style({ transform: 'translateX(-98vw)' })),
      state('mob2', style({ transform: 'translateX(-197vw)' })),
      state('mob3', style({ transform: 'translateX(-295vw)' })),
      state('mob4', style({ transform: 'translateX(-392vw)' })),
  
      transition('midle <=> end', animate('1s')),
      transition('midle <=> start', animate('1s')),
      transition('end <=> start', animate('1s')),

      transition('* <=> mob1', animate('1s')),
      transition('* <=> mob2', animate('1s')),
      transition('* <=> mob3', animate('1s')),
      transition('* <=> mob4', animate('1s')),
    ]),
  ]
})
export class MainComponent implements OnInit{
  sliderState = 'start'
  sliderCounter:number = 0 
  sliderInterval:any
  isMobile = false;
 
  curLang = "en"
  en = langEn
  ru = langRu
  translates:Translate = {
    "en": this.en.en,
    "ru": this.ru.ru,
  }
  constructor(public dialog: MatDialog, private service:FormService){
    service.curLang.subscribe(res=> this.curLang = res!)
  }
  openDialog(){
    const dialogRef = this.dialog.open(FormDialogComponent);
  }

  ngOnInit(): void {
    // if(window.innerWidth <= 800) this.isMobile = true
    // this.sliderInterval = setInterval(()=>{
    //   if(this.isMobile){
    //     this.sliderMoveMobile()
    //   }else this.sliderMove()
      
    // },5000)
  }

  sliderMove():void{
    this.sliderCounter++
    if(this.sliderCounter===1){
      this.sliderState = 'midle'
    }
    if(this.sliderCounter===2){
      this.sliderState = 'end'
    }
    if(this.sliderCounter===3){
      this.sliderState = 'start'
      this.sliderCounter = 0
    }
  }

  sliderMoveMobile(){
    this.sliderCounter++
    if(this.sliderCounter===1){
      this.sliderState = 'mob1'
    }
    if(this.sliderCounter===2){
      this.sliderState = 'mob2'
    }
    if(this.sliderCounter===3){
      this.sliderState = 'mob3'
      
    }
    if(this.sliderCounter===4){
      this.sliderState = 'mob4'
      
    }
    if(this.sliderCounter===5){
      this.sliderState = 'start'
      this.sliderCounter = 0
    }
  }

  changeSlide(num:number){
    this.sliderCounter = num; 
    if(this.isMobile){
      this.sliderMoveMobile()
    }else{
      this.sliderMove()
    }
    
    clearInterval(this.sliderInterval)
    this.sliderInterval = setInterval(()=>{
      if(this.isMobile){
        this.sliderMoveMobile()
      }else this.sliderMove()
    },5000)
  }

  pay(){
    window.open('https://workspace.investments-today.online/','_blank') 
  }

  news(){
    window.open('https://www.profinance.ru','_blank') 
  }

  openDocModal1() {
    let docModal1 = document.getElementById('docModal1');
    docModal1?.classList.add('_active-modal');
  }
  closeDocModal1(){
    let docModal1 = document.getElementById('docModal1');
    docModal1?.classList.remove('_active-modal');
  }

  openDocModal2() {
    let docModal2 = document.getElementById('docModal2');
    docModal2?.classList.add('_active-modal');
  }
  closeDocModal2(){
    let docModal2 = document.getElementById('docModal2');
    docModal2?.classList.remove('_active-modal');
  }

  openDocModal3() {
    let docModal3 = document.getElementById('docModal3');
    docModal3?.classList.add('_active-modal');
  }
  closeDocModal3(){
    let docModal3 = document.getElementById('docModal3');
    docModal3?.classList.remove('_active-modal');
  }

  showListItemBody(bodyId:string, svgId:string){
    let allBodyes = document.querySelectorAll('.item-body');
    let allSvgs = document.querySelectorAll('.headerSvg');
    let curBody = document.getElementById(bodyId);
    let curSvg = document.getElementById(svgId);
    if(curBody?.classList.contains('_active-body')){
      curBody.classList.remove('_active-body');
      curSvg?.classList.remove('_active-svg');
    }else {
      allBodyes.forEach(body => {
        body.classList.remove('_active-body');
      });
      allSvgs.forEach(svg => {
        svg.classList.remove('_active-svg');
      });
      
      curBody?.classList.add('_active-body');
      curSvg?.classList.add('_active-svg');
    }
    
    
  }
}


