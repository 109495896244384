<div class="trade-nav">
    <div class="content-wrapper" style="display: flex;">
        <div class="nav-item" (mouseover)="overNav(1)" (mouseleave)="leaveNav(1)">
            <p class="nav-text" [routerLink]="'/trading/trading-account'">{{translates[curLang].text1}}</p>
            <div class="bottom-line" [@line]="lineState"></div>
        </div>
        <div class="nav-item"  (mouseover)="overNav(2)" (mouseleave)="leaveNav(2)">
            <p class="nav-text" [routerLink]="'/trading/individual-investment-account'">{{translates[curLang].text2}}</p>
            <div class="bottom-line" [@line]="lineState2"></div>
        </div>
    </div>
</div>
<app-main-title [img]="'/trading-accaunt/arrows.jpg'" [title]="translates[curLang].text3" [text]="translates[curLang].text4"
    [btnText]="translates[curLang].text5" [isPay]="true"></app-main-title>
<app-reasons [title1]="translates[curLang].text6" [img1]="'/trading-accaunt/1.svg'"
    [title2]="translates[curLang].text7" [img2]="'/trading-accaunt/2.svg'"
    [title3]="translates[curLang].text8" [img3]="'/trading-accaunt/3.svg'"></app-reasons>
<div class="advantages">
    <div class="content-wrapper">
        <h2 class="adv-title">{{translates[curLang].text9}}</h2>
        <div class="adv-container">
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text10}}</h2>
                <p class="item-text">{{translates[curLang].text11}}</p>
            </div>
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text12}}</h2>
                <p class="item-text">{{translates[curLang].text13}}</p>
            </div>
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text14}}</h2>
                <p class="item-text">{{translates[curLang].text15}}</p>
            </div>
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text16}}</h2>
                <p class="item-text">{{translates[curLang].text17}}</p>
            </div>
        </div>
    </div>
</div>

<app-how-to-investor></app-how-to-investor>
<div class="mk-block">
    <div class="content-wrapper">
        <div class="mk-container">
            <div class="mk-left item">
                <h2 class="mk-title">{{translates[curLang].text18}} <br> {{translates[curLang].text19}}</h2>
                <p class="mk-text">{{translates[curLang].text20}}</p>
                <div class="mk-controls">
                    <button class="green-button" style="margin-right: 10px;" (click)="pay()">{{translates[curLang].text21}}</button>
                    <button class="green-button tranparent-btn" (click)="openDialog()">{{translates[curLang].text22}}</button>
                </div>
            </div>
            <div class="mk-middle item">
                <div class="blocks-container">
                    <div class="img-container">
                        <img src="../../../assets/trading-accaunt/5blocks.svg"  alt="">
                    </div>
                    <h2 class="big-num">500 000 ₽</h2>
                    <p class="small-text">{{translates[curLang].text23}}</p>
                </div>

            </div>
            <div class="mk-right item">
                <div class="first r-item">
                    <div class="blocks-container">
                        <div class="img-container">
                            <img src="../../../assets/trading-accaunt/1block.svg" alt="">
                        </div>
                        <h2 class="big-num">100 000 ₽</h2>
                        <p class="small-text">{{translates[curLang].text24}}</p>
                    </div>
                </div>
                <div class="seccond r-item">
                    <div class="simbol">+</div>
                </div>
                <div class="end r-item">
                    <div class="blocks-container">
                        <div class="img-container">
                            <img src="../../../assets/trading-accaunt/4blocks.svg" alt="">
                        </div>
                        <h2 class="big-num">400 000 ₽</h2>
                        <p class="small-text">{{translates[curLang].text25}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="iis-block">
    <div class="content-wrapper">
        <div class="iis-container">
            <div class="funds iis-item">
                <app-see-also-funds [title1]="translates[curLang].text26"
                    [preTitle1]="translates[curLang].text27" [Num1]="'1%'"
                    [infoBottom1]="translates[curLang].text28" [Num2]="'0,08%'"
                    [infoBottom2]="translates[curLang].text29" [preNum3]="translates[curLang].text33" [Num3]="'50 000₽'"
                    [infoBottom3]="translates[curLang].text30" [img1]="'/trading-accaunt/Dollar_Bulb_FINAL 1.png'"
                    [title2]="translates[curLang].text31"
                    [preTitle2]="translates[curLang].text32" [Num4]="'4%'"
                    [infoBottom4]="translates[curLang].text28" [Num5]="'0.2%'"
                    [infoBottom5]="translates[curLang].text39" [preNum6]="translates[curLang].text33" [Num6]="'30 000₽'"
                    [infoBottom6]="translates[curLang].text30" [img2]="'/trading-accaunt/Safe_FINAL 1.png'" [inRow]="false"
                    [bColor]="'#fff'"
                    [routerLink]="'/solutions/du-iis'"
                    >
                    
                </app-see-also-funds>
            </div>
            <div class="info iis-item">
                <h2 class="mk-title">{{translates[curLang].text34}}</h2>
                <p class="mk-text">{{translates[curLang].text35}} <br> {{translates[curLang].text36}} <br> {{translates[curLang].text37}}</p>
                <div class="mk-controls">
                    <button class="green-button" style="margin-right: 15px;" (click)="pay()">{{translates[curLang].text21}}</button>
                    <button class="green-button tranparent-btn"  [routerLink]="'/solutions/du-iis'">{{translates[curLang].text38}}</button>
                </div>
                <div class="green">
                    <img src="" alt="">
                    <p class="green-text"></p>
                </div>
            </div>

        </div>
        
    </div>
</div>
<div class="instruction">
    <div class="content-wrapper">
        <div class="intruct-container">
            <div class="intruct-item" [ngClass]="{'active':info1}" (click)="showInfo1()">
                <div class="text" >
                    <span>{{translates[curLang].text39}}</span>
                    <p>{{translates[curLang].text40}}</p>
                </div>
                <div class="img-container">
                    <img src="../../../assets/header/arrow.svg"  alt="">
                </div>
            </div>

            <div class="intruct-item" [ngClass]="{'active':info2}" (click)="showInfo2()">
                <div class="text">
                    <span>{{translates[curLang].text41}}</span>
                    <p><br> {{translates[curLang].text42}}
                        <br>- {{translates[curLang].text43}}
                        <br>- {{translates[curLang].text44}}
                        <br>{{translates[curLang].text45}}
                        
                       </p>
                </div>
                <div class="img-container">
                    <img src="../../../assets/header/arrow.svg"  alt="">
                </div>
            </div>

            <div class="intruct-item" [ngClass]="{'active':info3}" (click)="showInfo3()">
                <div class="text">
                    <span>{{translates[curLang].text46}}</span>
                    <p>{{translates[curLang].text47}}</p>
                </div>
                <div class="img-container">
                    <img src="../../../assets/header/arrow.svg"  alt="">
                </div>
            </div>
        </div>
    </div>
</div>
<app-question-block></app-question-block>
<div class="pick-fund">
    <div class="content-wrapper">
        <div class="funds">
            <div class="fund-item">
                <img src="../../assets/trading-accaunt/start.png" alt="" class="fund-img">
                <div class="top-info">
                    <h2 class="top-title">{{translates[curLang].text48}}</h2>
                    <p class="top-text">{{translates[curLang].text49}}</p>
                </div>
                <div class="middle-info">
                    <div class="info">
                        <h3>{{translates[curLang].text33}}</h3>
                        <h2>30 000₽</h2>
                        <p class="middle-text">{{translates[curLang].text50}}</p>
                    </div>
                    <div class="info">
                        
                        <h2>RUB</h2>
                        <p class="middle-text">{{translates[curLang].text51}}</p>
                    </div>
                    <div class="info">
                        <h3>{{translates[curLang].text33}}</h3>
                        <h2>0, 15%</h2>
                        <p class="middle-text">{{translates[curLang].text52}}</p>
                    </div>
                    
                </div>
            </div>

            <div class="fund-item">
                <div class="top-info" style="min-height: 0;">
                    <h2 class="top-title">{{translates[curLang].text53}}</h2>
                </div>
                <div class="content-table">
                    <div class="table-header">
                        <div class="first-col" style="min-height: 0;">
                            <p>{{translates[curLang].text54}}</p>
                        </div>
                        <div class="sec-col" style="min-height: 0;">
                            <p>{{translates[curLang].text55}}</p>
                        </div>
                        <div class="third-col" style="min-height: 0;">
                            <p>{{translates[curLang].text56}}</p>
                        </div>
                    </div>
                    <div class="table-row">
                        <div class="first-col">
                            <p class="main-p">{{translates[curLang].text57}}</p>
                            <p class="pre-main">KOGK</p>
                        </div>
                        <div class="sec-col">
                            <p>{{translates[curLang].text58}}</p>
                        </div>
                        <div class="third-col">
                            <p class="main-p">46 200₽</p>
                        </div>
                    </div>
                    <div class="table-row">
                        <div class="first-col">
                            <p class="main-p">{{translates[curLang].text59}}</p>
                            <p class="pre-main">DZRDP</p>
                        </div>
                        <div class="sec-col">
                            <p>{{translates[curLang].text58}}</p>
                        </div>
                        <div class="third-col">
                            <p class="main-p">2 490₽</p>
                        </div>
                    </div>
                    <div class="table-row">
                        <div class="first-col">
                            <p class="main-p">{{translates[curLang].text60}}</p>
                            <p class="pre-main">MOEX</p>
                        </div>
                        <div class="sec-col">
                            <p>{{translates[curLang].text61}}</p>
                        </div>
                        <div class="third-col">
                            <p class="main-p">122,7₽</p>
                        </div>
                    </div>
                </div>
                
            </div>

        </div>
        
    </div>
</div>
<app-documents></app-documents>