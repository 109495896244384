import {Component} from '@angular/core';

@Component({
  selector: 'app-portfels',
  templateUrl: './portfels.component.html',
  styleUrls: ['./portfels.component.scss']
})
export class PortfelsComponent {
  diagram_number: number = 1

  switchNumber(num: number) {
    this.diagram_number = num

    if (num === 1) {
      document.getElementById('1')!.classList.add('active')
      document.getElementById('2')!.classList.remove('active')
      document.getElementById('3')!.classList.remove('active')
    } else if (num === 2) {
      document.getElementById('1')!.classList.remove('active')
      document.getElementById('2')!.classList.add('active')
      document.getElementById('3')!.classList.remove('active')
    } else if (num === 3) {
      document.getElementById('1')!.classList.remove('active')
      document.getElementById('2')!.classList.remove('active')
      document.getElementById('3')!.classList.add('active')
    }
  }
}
