<footer>
    <div class="top-wrapper">
        <div class="content-wrapper">
            <div class="footer-top">
                <div class="footer-section logo">
                    <img src="../../../assets/logo2.png" class="logo-img" alt="logo" style="cursor: pointer;" [routerLink]="'/home'">
                    
                </div>
                <div class="footer-section info">
                    <h3 class="sec-title">{{translates[curLang].text1}}</h3>
                    <p routerLink="/about-us/about-us">{{translates[curLang].text2}}</p>
                    <p (click)="news()">{{translates[curLang].text3}}</p>       
                    <p [routerLink]="'/about-us/contacts'">{{translates[curLang].text4}}</p>
                    <p [routerLink]="'/about-us/work-with-us'">{{translates[curLang].text5}}</p>
                </div>
                <div class="footer-section info">
                    <h3 class="sec-title">{{translates[curLang].text6}}</h3>
                    <p [routerLink]="'/analytics/instruments'">{{translates[curLang].text7}}</p>
                </div>
                <div class="footer-section info">
                    <h3 class="sec-title">{{translates[curLang].text8}}</h3>
                    <p [routerLink]="'/documents/information/general-documents'">{{translates[curLang].text9}}</p>
                    <p [routerLink]="'/documents/information/compliance'">{{translates[curLang].text10}}</p>
                </div>
                <div class="footer-section info">
                    <h3 class="sec-title">{{translates[curLang].text11}}</h3>
                    <p [routerLink]="'services/margin-trading'">{{translates[curLang].text12}}</p>
                    <p [routerLink]="'/education-block/faq/general-issues'">{{translates[curLang].text13}}</p>
                </div>
                <div class="footer-section info">
                    <h3 class="sec-title">{{translates[curLang].text14}}</h3>
                    <p>Trade Room</p>
                    
                    <div class="img-wrapper">
                        <!-- <img src="../../../assets/android.svg" (click)="goGoogle()" alt="">
                        <img src="../../../assets/ios.svg" (click)="goAple()" alt=""> -->
                        <!-- <div class="cabinet" (click)="pay()">
                            <div class="img">
                              <img src="../../../assets/myCab.svg" alt="">
                            </div>
                            <div class="text">
                              
                              <p class="main-text">{{translates[curLang].text21}}</p>
                            </div>
                          </div> -->
                        <button class="green-button tranparent-btn">{{translates[curLang].text21}}</button>
                    </div>
                </div>
                <div class="footer-section links">
                    <button class="green-button tranparent-btn" (click)="openDialog()">{{translates[curLang].text15}}</button>
                    <button class="green-button tranparent-btn" (click)="goTelegram()">Telegram</button>
                    <button class="green-button tranparent-btn" (click)="goWhatsApp()">WhatsApp</button>
                </div>
            </div>
        </div>
    </div>
   
    <div class="content-wrapper">
        <div class="footer-bottom">
            <div class="footer-section" >
                <p>{{translates[curLang].text16}}</p>
                <p>{{translates[curLang].text17}}</p>
                <p>{{translates[curLang].text22}}</p>
            </div>
            <div class="footer-section" >
                <p>{{translates[curLang].text18}}</p>
                <p>{{translates[curLang].text19}}</p>
                <p>{{translates[curLang].text20}} 7703622255 1077746005666</p>
            </div>
            <div class="footer-section years">
                <p class="date" >©2004–2023</p>
            </div>
        </div>
    </div>
</footer>
