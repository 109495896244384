<div class="instruments">
    <div class="content-wrapper">
        <div class="container">
            <h2>{{translates[curLang].text1}}</h2>
            <div class="info-container">
                <div class="navigation">
                    <div class="nav-item" (click)="showCalendar()">
                        <div class="text" [ngClass]="{'active':isCalendar}">{{translates[curLang].text2}}</div>
                        <div class="image"><img src="../../../assets/instruments/1.svg" alt=""></div>
                    </div>
                    <div class="nav-item" (click)="showProfile()">
                        <div class="text" [ngClass]="{'active':isProfile}">{{translates[curLang].text3}}</div>
                        <div class="image"><img src="../../../assets/instruments/2.svg" alt=""></div>
                    </div>
                    <div class="nav-item" (click)="showAnalise()">
                        <div class="text" [ngClass]="{'active':isAnalise}">{{translates[curLang].text4}}</div>
                        <div class="image"><img src="../../../assets/instruments/3.svg" alt=""></div>
                    </div>
                    <div class="nav-item" (click)="showAnalise2()">
                        <div class="text" [ngClass]="{'active':isAnalise2}">{{translates[curLang].text5}}</div>
                        <div class="image"><img src="../../../assets/instruments/4.svg" alt=""></div>
                    </div>
                </div>
                <div class="trading-block" *ngIf="isCalendar">
                    <div class="outerwidget___3nf3w"><div class="widget___DemLF"><div class="tradingview-widget-container" style="width: 100%; height: 400px;">
                        <iframe scrolling="no" allowtransparency="true" frameborder="0" src="https://www.tradingview-widget.com/embed-widget/events/?locale=ru#%7B%22width%22%3A%22100%25%22%2C%22height%22%3A%22100%25%22%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Afalse%2C%22importanceFilter%22%3A%22-1%2C0%2C1%22%2C%22utm_source%22%3A%22akbf.ru%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22events%22%7D" style="box-sizing: border-box; display: block; height: 100%; width: 100%;"></iframe>
                        
                      <style>
                    .tradingview-widget-copyright {
                        font-size: 13px !important;
                        line-height: 32px !important;
                        text-align: center !important;
                        vertical-align: middle !important;
                        /* @mixin sf-pro-display-font; */
                        font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif !important;
                        color: #9db2bd !important;
                    }
                    
                    .tradingview-widget-copyright .blue-text {
                        color: #2962FF !important;
                    }
                    
                    .tradingview-widget-copyright a {
                        text-decoration: none !important;
                        color: #9db2bd !important;
                    }
                    
                    .tradingview-widget-copyright a:visited {
                        color: #9db2bd !important;
                    }
                    
                    .tradingview-widget-copyright a:hover .blue-text {
                        color: #1E53E5 !important;
                    }
                    
                    .tradingview-widget-copyright a:active .blue-text {
                        color: #1848CC !important;
                    }
                    
                    .tradingview-widget-copyright a:visited .blue-text {
                        color: #2962FF !important;
                    }
                    </style></div></div><div class="widgetcopyright___15nSt"><a href="https://ru.tradingview.com/markets/currencies/economic-calendar/" target="_blank" rel="noreferrer"><span>Экономический календарь</span></a> <!-- -->от TradingView</div></div>
                </div>
                <div class="trading-block" *ngIf="isProfile">
                    <div class="widget___DemLF"><div class="tradingview-widget-container" style="width: 100%; height: 400px;">
                        <iframe scrolling="no" allowtransparency="true" frameborder="0" src="https://s.tradingview.com/embed-widget/symbol-profile/?locale=ru&amp;symbol=MOEX%3AGAZP#%7B%22width%22%3A%22100%25%22%2C%22height%22%3A%22100%25%22%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Afalse%2C%22utm_source%22%3A%22akbf.ru%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22symbol-profile%22%7D" style="box-sizing: border-box; display: block; height: 100%; width: 100%;"></iframe>
                        
                      <style>
                    .tradingview-widget-copyright {
                        font-size: 13px !important;
                        line-height: 32px !important;
                        text-align: center !important;
                        vertical-align: middle !important;
                        /* @mixin sf-pro-display-font; */
                        font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif !important;
                        color: #9db2bd !important;
                    }
                
                    .tradingview-widget-copyright .blue-text {
                        color: #2962FF !important;
                    }
                
                    .tradingview-widget-copyright a {
                        text-decoration: none !important;
                        color: #9db2bd !important;
                    }
                
                    .tradingview-widget-copyright a:visited {
                        color: #9db2bd !important;
                    }
                
                    .tradingview-widget-copyright a:hover .blue-text {
                        color: #1E53E5 !important;
                    }
                
                    .tradingview-widget-copyright a:active .blue-text {
                        color: #1848CC !important;
                    }
                
                    .tradingview-widget-copyright a:visited .blue-text {
                        color: #2962FF !important;
                    }
                    </style></div></div>
                </div>
                <div class="trading-block" *ngIf="isAnalise">
                    <div class="widget___DemLF"><div class="tradingview-widget-container" style="width: 100%; height: 400px;">
                        <iframe scrolling="no" allowtransparency="true" frameborder="0" src="https://s.tradingview.com/embed-widget/technical-analysis/?locale=ru#%7B%22interval%22%3A%221m%22%2C%22width%22%3A%22100%25%22%2C%22isTransparent%22%3Afalse%2C%22height%22%3A%22100%25%22%2C%22symbol%22%3A%22MOEX%3AGAZP%22%2C%22showIntervalTabs%22%3Atrue%2C%22colorTheme%22%3A%22light%22%2C%22utm_source%22%3A%22akbf.ru%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22technical-analysis%22%2C%22page-uri%22%3A%22akbf.ru%2Fanalytics%2Finstruments%3Ftype%3Dtech%26code%3DGAZP%22%7D" style="box-sizing: border-box; display: block; height: 100%; width: 100%;"></iframe>
                        
                      <style>
                  .tradingview-widget-copyright {
                      font-size: 13px !important;
                      line-height: 32px !important;
                      text-align: center !important;
                      vertical-align: middle !important;
                      /* @mixin sf-pro-display-font; */
                      font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif !important;
                      color: #9db2bd !important;
                  }
              
                  .tradingview-widget-copyright .blue-text {
                      color: #2962FF !important;
                  }
              
                  .tradingview-widget-copyright a {
                      text-decoration: none !important;
                      color: #9db2bd !important;
                  }
              
                  .tradingview-widget-copyright a:visited {
                      color: #9db2bd !important;
                  }
              
                  .tradingview-widget-copyright a:hover .blue-text {
                      color: #1E53E5 !important;
                  }
              
                  .tradingview-widget-copyright a:active .blue-text {
                      color: #1848CC !important;
                  }
              
                  .tradingview-widget-copyright a:visited .blue-text {
                      color: #2962FF !important;
                  }
                  </style></div></div>
                </div>
                <div class="trading-block" *ngIf="isAnalise2">
                    <div class="widget___DemLF"><div class="tradingview-widget-container" style="width: 100%; height: 400px;">
                        <iframe scrolling="no" allowtransparency="true" frameborder="0" src="https://s.tradingview.com/embed-widget/financials/?locale=ru#%7B%22symbol%22%3A%22MOEX%3AGAZP%22%2C%22colorTheme%22%3A%22light%22%2C%22isTransparent%22%3Afalse%2C%22largeChartUrl%22%3A%22%22%2C%22displayMode%22%3A%22regular%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A%22100%25%22%2C%22utm_source%22%3A%22akbf.ru%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22financials%22%2C%22page-uri%22%3A%22akbf.ru%2Fanalytics%2Finstruments%3Ftype%3Danalyze%26code%3DGAZP%22%7D" style="box-sizing: border-box; display: block; height: 100%; width: 100%;"></iframe>
                        
                      <style>
                  .tradingview-widget-copyright {
                      font-size: 13px !important;
                      line-height: 32px !important;
                      text-align: center !important;
                      vertical-align: middle !important;
                      /* @mixin sf-pro-display-font; */
                      font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif !important;
                      color: #9db2bd !important;
                  }
              
                  .tradingview-widget-copyright .blue-text {
                      color: #2962FF !important;
                  }
              
                  .tradingview-widget-copyright a {
                      text-decoration: none !important;
                      color: #9db2bd !important;
                  }
              
                  .tradingview-widget-copyright a:visited {
                      color: #9db2bd !important;
                  }
              
                  .tradingview-widget-copyright a:hover .blue-text {
                      color: #1E53E5 !important;
                  }
              
                  .tradingview-widget-copyright a:active .blue-text {
                      color: #1848CC !important;
                  }
              
                  .tradingview-widget-copyright a:visited .blue-text {
                      color: #2962FF !important;
                  }
                  </style></div></div>
                </div>
                
            </div>
        </div>
    </div>
</div>