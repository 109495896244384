<div class="trade-nav">
    <div class="content-wrapper" style="display: flex;">
        <div class="nav-item" (mouseover)="overNav(1)" (mouseleave)="leaveNav(1)">
            <p class="nav-text" [routerLink]="'/solutions/trust-strategies'">{{translates[curLang].text1}}</p>
            <div class="bottom-line" [@line]="lineState"></div>
        </div>
        <div class="nav-item"  (mouseover)="overNav(2)" (mouseleave)="leaveNav(2)">
            <p class="nav-text" [routerLink]="'/solutions/du-iis'">{{translates[curLang].text2}}</p>
            <div class="bottom-line" [@line]="lineState2"></div>
        </div>
    </div>
</div>
<app-main-title 
    [img]="'/monetary/monetary.jpg'" 
    [title]="translates[curLang].text3" 
    [text]="translates[curLang].text4"
    [btnText]="translates[curLang].text5"
    [showControls]="true"
    [controlText1]="translates[curLang].text6"
    [controlText2]="translates[curLang].text7"
    [textColor]="'#000'"
    [link1]="'/solutions/trust-strategies'"
    [link2]="'/solutions/trust-strategies/monetary'"
></app-main-title>
<div class="advantages">
    <div class="content-wrapper">
        <div class="adv-container">
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text8}}</h2>
            </div>
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text9}}</h2>
                <p class="item-text">{{translates[curLang].text10}}</p>
            </div>
            <div class="adv-item">
                <h2 class="item-title">100%</h2>
                <p class="item-text">{{translates[curLang].text11}}</p>
            </div>
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text12}}</h2>
                <p class="item-text">{{translates[curLang].text13}}</p>
            </div>
        </div>
    </div>
</div>

<div class="img-text-block">
    <div class="content-wrapper" style="display: flex;">
        <div class="img-container">
            <img src="../../../assets/trust-strategies/gears.png" alt="">
        </div>
        <div class="info-container">
            <h2>{{translates[curLang].text14}}</h2>
            <p>{{translates[curLang].text15}}<sup>1</sup> {{translates[curLang].text16}}<sup>2</sup>. {{translates[curLang].text17}}</p>
            <button class="green-button" style="margin-top: 30px; width: fit-content;" (click)="openDialog()">{{translates[curLang].text18}}</button>
        </div>
    </div>
</div>
<div class="strategy">
    <div class="content-wrapper">
        <div class="content">
            <div class="top">
                <h2>{{translates[curLang].text19}}</h2>
                <p>{{translates[curLang].text20}}</p>
            </div>
            <div class="percent-block">
                <div class="green-item">
                    <h3>{{translates[curLang].text21}} 11%</h3>
                    <p>{{translates[curLang].text22}}</p>
                </div>
                <div class="plus">+</div>
                <div class="green-item">
                    <h3>{{translates[curLang].text21}} 4%</h3>
                    <p>{{translates[curLang].text23}}</p>
                </div>
            </div>
            <div class="info-block">
                <div class="info-item">
                    <h3>7 {{translates[curLang].text24}}</h3>
                    <p>{{translates[curLang].text25}}</p>
                </div>
                <div class="info-item">
                    <h3>{{translates[curLang].text26}}</h3>
                    <p>{{translates[curLang].text27}}</p>
                </div>
            </div>
            <div class="info-block">
                <div class="info-item">
                    <h3>{{translates[curLang].text28}} 100 000₽</h3>
                    <p>{{translates[curLang].text29}}</p>
                </div>
                <div class="info-item">
                    <h3>{{translates[curLang].text28}} 100 000₽</h3>
                    <p>{{translates[curLang].text30}}</p>
                </div>
            </div>
            <button class="green-button tranparent-btn" (click)="openDialog()">{{translates[curLang].text31}}</button>
        </div>
    </div>
</div>
<div class="how-it-works">
    <div class="content-wrapper">
        <h2>{{translates[curLang].text32}}</h2>
        <img src="../../../../assets/monetary/redr-desc.png" width="100%" alt="">
    </div>
    
</div>
<app-how-to-investor></app-how-to-investor>
<app-reasons 
    [title1]="'1,6%'" 
    [text1]="translates[curLang].text33"
    [img1]="'/trust-strategies/1.svg'"
    [title2]="'1%'" 
    [text2]="translates[curLang].text34"
    [img2]="'/trust-strategies/1.svg'"
    [title3]="'5%'" 
    [text3]="translates[curLang].text35"
    [img3]="'/trust-strategies/3.svg'"
></app-reasons>
<div class="instruction">
    <div class="content-wrapper">
        <div class="intruct-container">
            <div class="intruct-item" [ngClass]="{'active':info1}" (click)="showInfo1()">
                <div class="text" >
                    <span>{{translates[curLang].text36}}</span>
                    <p>{{translates[curLang].text37}}</p>
                </div>
                <div class="img-container">
                    <img src="../../../assets/header/arrow.svg"  alt="">
                </div>
            </div>

            <div class="intruct-item" [ngClass]="{'active':info2}" (click)="showInfo2()">
                <div class="text">
                    <span>{{translates[curLang].text38}}</span>
                    <p>{{translates[curLang].text39}}</p>
                </div>
                <div class="img-container">
                    <img src="../../../assets/header/arrow.svg"  alt="">
                </div>
            </div>

            <div class="intruct-item" [ngClass]="{'active':info3}" (click)="showInfo3()">
                <div class="text">
                    <span>{{translates[curLang].text40}}</span>
                    <p>- {{translates[curLang].text41}}</p>
                </div>
                <div class="img-container">
                    <img src="../../../assets/header/arrow.svg"  alt="">
                </div>
            </div>
        </div>
    </div>
</div>
<app-question-block></app-question-block>
<app-documents></app-documents>