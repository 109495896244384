<div class="main-header">
    <div class="content-wrapper" style="height: 100%;">
        <div class="bot" (click)="goTelegram()">
            <div class="circle">
                <img src="../../../assets/tg-dark.svg" alt="">
            </div>
            <div class="text">
                <span>{{translates[curLang].text1}}</span>
            </div>
        </div>
        <div class="content">
            <h2>{{translates[curLang].text2}}</h2>
        </div>
    </div>
</div>
<div class="info-block">
    <div class="content-wrapper">
        <div class="info">
            <div class="left___1_udt">
                <div class="faq-questions___vJRtc">
                    <div class="question___3Twqd">
                        <div class="q___LBco0">
                            <p class="typo___oYDNK secondary-font___1x6NP"
                                style="font-size: 1.5rem; line-height: 1.16667; margin-bottom: 1.5rem;">{{translates[curLang].text3}}</p>
                        </div>
                        <div class="a___1pUmK">
                            <p>{{translates[curLang].text4}}</p>
                            <ol>
                                <li><a href="https://www.moex.com/s1161"> {{translates[curLang].text5}}</a>, {{translates[curLang].text6}}</li>
                                <li> <a href="https://www.moex.com/ru/bondization/">{{translates[curLang].text7}}</a>. {{translates[curLang].text8}}</li>
                            </ol>
                            <p><a href="https://www.moex.com/msn/stock-instruments">{{translates[curLang].text9}}</a>.</p>
                        </div>
                    </div>
                    <div class="question___3Twqd">
                        <div class="q___LBco0">
                            <p class="typo___oYDNK secondary-font___1x6NP"
                                style="font-size: 1.5rem; line-height: 1.16667; margin-bottom: 1.5rem;">{{translates[curLang].text10}}</p>
                        </div>
                        <div class="a___1pUmK">
                            <p>{{translates[curLang].text11}} <a
                                    href="https://spbexchange.ru/ru/stocks/inostrannye/Instruments.aspx">{{translates[curLang].text12}}</a> {{translates[curLang].text13}}</p>
                            <p><a href="https://spbexchange.ru/ru/stocks/inostrannye/Instruments.aspx">{{translates[curLang].text14}}</a></p>
                        </div>
                    </div>
                    <div class="question___3Twqd">
                        <div class="q___LBco0">
                            <p class="typo___oYDNK secondary-font___1x6NP"
                                style="font-size: 1.5rem; line-height: 1.16667; margin-bottom: 1.5rem;">{{translates[curLang].text15}}</p>
                        </div>
                        <div class="a___1pUmK">
                            <p>{{translates[curLang].text16}}</p>
                            <p>{{translates[curLang].text17}}</p>
                            <p><a href="https://www.moex.com/ru/derivatives/">{{translates[curLang].text18}}</a>.</p>
                        </div>
                    </div>
                    <div class="question___3Twqd">
                        <div class="q___LBco0">
                            <p class="typo___oYDNK secondary-font___1x6NP"
                                style="font-size: 1.5rem; line-height: 1.16667; margin-bottom: 1.5rem;">{{translates[curLang].text19}}</p>
                        </div>
                        <div class="a___1pUmK">
                            <p>{{translates[curLang].text20}}</p>
                            <p>{{translates[curLang].text21}}</p>
                            <p><a href="https://www.moex.com/s135">{{translates[curLang].text22}}</a>.</p>
                        </div>
                    </div>
                    <div class="question___3Twqd">
                        <div class="q___LBco0">
                            <p class="typo___oYDNK secondary-font___1x6NP"
                                style="font-size: 1.5rem; line-height: 1.16667; margin-bottom: 1.5rem;">{{translates[curLang].text23}}</p>
                        </div>
                        <div class="a___1pUmK">
                            <p>{{translates[curLang].text24}} - <a
                                    href="https://ru.wikipedia.org/wiki/%D0%AD%D0%BC%D0%B8%D1%81%D1%81%D0%B8%D1%8F_%D1%86%D0%B5%D0%BD%D0%BD%D1%8B%D1%85_%D0%B1%D1%83%D0%BC%D0%B0%D0%B3">{{translates[curLang].text25}}</a> <a
                                    href="https://ru.wikipedia.org/wiki/%D0%A6%D0%B5%D0%BD%D0%BD%D0%B0%D1%8F_%D0%B1%D1%83%D0%BC%D0%B0%D0%B3%D0%B0">{{translates[curLang].text26}}</a>, {{translates[curLang].text27}} (<a
                                    href="https://ru.wikipedia.org/wiki/%D0%90%D0%BA%D1%86%D0%B8%D0%BE%D0%BD%D0%B5%D1%80">{{translates[curLang].text28}}</a>)
                                    {{translates[curLang].text29}} <a
                                    href="https://ru.wikipedia.org/wiki/%D0%9F%D1%80%D0%B8%D0%B1%D1%8B%D0%BB%D1%8C">{{translates[curLang].text30}}</a> <a
                                    href="https://ru.wikipedia.org/wiki/%D0%90%D0%BA%D1%86%D0%B8%D0%BE%D0%BD%D0%B5%D1%80%D0%BD%D0%BE%D0%B5_%D0%BE%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE">{{translates[curLang].text31}}</a> {{translates[curLang].text32}} <a
                                    href="https://ru.wikipedia.org/wiki/%D0%94%D0%B8%D0%B2%D0%B8%D0%B4%D0%B5%D0%BD%D0%B4">{{translates[curLang].text33}}</a>,
                                    {{translates[curLang].text34}} <a
                                    href="https://ru.wikipedia.org/wiki/%D0%98%D0%BC%D1%83%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE">{{translates[curLang].text35}}</a>,
                                    {{translates[curLang].text36}} <a
                                    href="https://ru.wikipedia.org/wiki/%D0%9B%D0%B8%D0%BA%D0%B2%D0%B8%D0%B4%D0%B0%D1%86%D0%B8%D1%8F_%D1%8E%D1%80%D0%B8%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B3%D0%BE_%D0%BB%D0%B8%D1%86%D0%B0">{{translates[curLang].text37}}</a>,
                                    {{translates[curLang].text38}}</p>
                            <p>{{translates[curLang].text39}} <a
                                    href="https://place.moex.com/products/bonds">{{translates[curLang].text40}}</a> {{translates[curLang].text41}}  Trade Room</p>
                        </div>
                    </div>
                    <div class="question___3Twqd">
                        <div class="q___LBco0">
                            <p class="typo___oYDNK secondary-font___1x6NP"
                                style="font-size: 1.5rem; line-height: 1.16667; margin-bottom: 1.5rem;">{{translates[curLang].text42}}
                            </p>
                        </div>
                        <div class="a___1pUmK">
                            <p>{{translates[curLang].text43}}</p>
                            <p>{{translates[curLang].text44}}</p>
                            <p>{{translates[curLang].text45}}</p>
                            <p>{{translates[curLang].text46}}</p>
                            <p>{{translates[curLang].text47}} <a
                                    href="https://place.moex.com/products/bonds">{{translates[curLang].text48}}</a> {{translates[curLang].text49}} Trade Room</p>
                        </div>
                    </div>
                    <div class="question___3Twqd">
                        <div class="q___LBco0">
                            <p class="typo___oYDNK secondary-font___1x6NP"
                                style="font-size: 1.5rem; line-height: 1.16667; margin-bottom: 1.5rem;">{{translates[curLang].text50}}</p>
                        </div>
                        <div class="a___1pUmK">
                            <p>{{translates[curLang].text51}}</p>
                        </div>
                    </div>
                    <div class="question___3Twqd">
                        <div class="q___LBco0">
                            <p class="typo___oYDNK secondary-font___1x6NP"
                                style="font-size: 1.5rem; line-height: 1.16667; margin-bottom: 1.5rem;">{{translates[curLang].text52}}</p>
                        </div>
                        <div class="a___1pUmK">
                            <p>{{translates[curLang].text53}}</p>
                            <p>{{translates[curLang].text54}}</p>
                            <p>{{translates[curLang].text55}}</p>
                        </div>
                    </div>
                    <div class="question___3Twqd">
                        <div class="q___LBco0">
                            <p class="typo___oYDNK secondary-font___1x6NP"
                                style="font-size: 1.5rem; line-height: 1.16667; margin-bottom: 1.5rem;">{{translates[curLang].text56}}
                            </p>
                        </div>
                        <div class="a___1pUmK">
                            <p>{{translates[curLang].text57}}</p>
                        </div>
                    </div>
                    <div class="question___3Twqd">
                        <div class="q___LBco0">
                            <p class="typo___oYDNK secondary-font___1x6NP"
                                style="font-size: 1.5rem; line-height: 1.16667; margin-bottom: 1.5rem;">{{translates[curLang].text58}}</p>
                        </div>
                        <div class="a___1pUmK">
                            <p>{{translates[curLang].text59}}</p>
                            <p>{{translates[curLang].text60}}</p>
                            <p>{{translates[curLang].text61}}</p>
                        </div>
                    </div>
                    <div class="question___3Twqd">
                        <div class="q___LBco0">
                            <p class="typo___oYDNK secondary-font___1x6NP"
                                style="font-size: 1.5rem; line-height: 1.16667; margin-bottom: 1.5rem;">{{translates[curLang].text62}}</p>
                        </div>
                        <div class="a___1pUmK">
                            <p>{{translates[curLang].text63}}</p>
                        </div>
                    </div>
                    <div class="question___3Twqd">
                        <div class="q___LBco0">
                            <p class="typo___oYDNK secondary-font___1x6NP"
                                style="font-size: 1.5rem; line-height: 1.16667; margin-bottom: 1.5rem;">{{translates[curLang].text64}}</p>
                        </div>
                        <div class="a___1pUmK">
                            <p>{{translates[curLang].text65}}:</p>
                            <p>{{translates[curLang].text66}}</p>
                            <p>{{translates[curLang].text67}}</p>
                            <p>{{translates[curLang].text68}}: <a href="https://www.moex.com/s1167">https://www.moex.com/s1167</a></p>
                            <p> </p>
                            <p>{{translates[curLang].text69}}</p>
                            <p>{{translates[curLang].text70}}</p>
                            <p>{{translates[curLang].text71}} <a href="https://fs.moex.com/files/710">{{translates[curLang].text72}}</a>.</p>
                            <p>{{translates[curLang].text73}}: <a href="https://www.moex.com/s135">https://www.moex.com/s135</a></p>
                            <p> </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>