<div class="content-wrapper">
    <div class="reasons">
        <h2 class="block-title">{{mainTitle}}</h2>
        <div class="reasons-wrapper">
            <div class="reason">
                <div class="reason-img">
                    <img width="75px" [src]="'../../assets'+img1" alt="">
                </div>
                <div class="reason-info">
                    <h3 class="info-title">{{title1}}</h3>
                    <p class="info-text">{{text1}}</p>
                </div>
            </div>

            <div class="reason" >
                <div class="reason-img">
                    <img width="75px" [src]="'../../assets'+img2" alt="">
                </div>
                <div class="reason-info">
                    <h3 class="info-title">{{title2}}</h3>
                    <p class="info-text">{{text2}}</p>
                </div>
            </div>

            <div class="reason">
                <div class="reason-img">
                    <img width="75px" [src]="'../../assets'+img3" alt="">
                </div>
                <div class="reason-info">
                    <h3 class="info-title">{{title3}}</h3>
                    <p class="info-text">{{text3}}</p>
                </div>
            </div>
        </div>
    </div>
</div>