import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormService } from 'src/app/services/form.service';
import { FormDialogComponent } from '../form-dialog/form-dialog.component';
import * as langEn from './locale_en.json'
import * as langRu from './locale_ru.json'
import { Translate } from 'src/app/services/form.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  curLang = "en"
  en = langEn
  ru = langRu
  translates:Translate = {
    "en": this.en.en,
    "ru": this.ru.ru,
  }
  constructor(private formService:FormService, public dialog: MatDialog){
    formService.curLang.subscribe(res=> this.curLang = res!)
  }


  openDialog(){
    const dialogRef = this.dialog.open(FormDialogComponent);
  }

  goGoogle(){
    window.open('https://play.google.com/store/apps/details?id=universal.trading.information.platform&hl=en_US','_blank') 
  }
  goAple(){
    window.open('https://apps.apple.com/ua/app/my-trading/id1069332482','_blank') 
  }
  goTelegram(){
    window.open('https://t.me/IK_SAV_Capital','_blank') 
  }

  news(){
    window.open('https://www.profinance.ru','_blank') 
  }
  pay(){
    window.open('https://workspace.investments-today.online/','_blank') 
  }
  goWhatsApp(){
    window.open('https://wa.me/447958113861','_blank') 
  }
}
