import { Component } from '@angular/core';
import * as langEn from './locale_en.json'
import * as langRu from './locale_ru.json'
import { FormService, Translate } from 'src/app/services/form.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent {
  curLang = "en"
  en = langEn
  ru = langRu
  translates:Translate = {
    "en": this.en.en,
    "ru": this.ru.ru,
  }
  constructor( private service:FormService){
    service.curLang.subscribe(res=> this.curLang = res!)
  }

}
