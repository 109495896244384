<div class="trade-nav">
    <div class="content-wrapper" style="display: flex;">
        <div class="nav-item" (mouseover)="overNav(1)" (mouseleave)="leaveNav(1)">
            <p class="nav-text" [routerLink]="'/trading/trading-account'">{{translates[curLang].text1}}</p>
            <div class="bottom-line" [@line]="lineState"></div>
        </div>
        <div class="nav-item"  (mouseover)="overNav(2)" (mouseleave)="leaveNav(2)">
            <p class="nav-text" [routerLink]="'/trading/individual-investment-account'">{{translates[curLang].text2}}</p>
            <div class="bottom-line" [@line]="lineState2"></div>
        </div>
    </div>
</div>
<app-main-title [img]="'/individual/percent13.jpg'" [title]="translates[curLang].text3" [text]="translates[curLang].text4"
    [btnText]="translates[curLang].text5" [isPay]="true">
</app-main-title>
<div class="advantages">
    <div class="content-wrapper">
        <div class="adv-container">
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text6}}</h2>
            </div>
            <div class="adv-item">
                <h2 class="item-title">13%</h2>
                <p class="item-text">{{translates[curLang].text7}}</p>
            </div>
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text8}} 4%</h2>
                <p class="item-text">{{translates[curLang].text9}}</p>
            </div>
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text10}}</h2>
                <p class="item-text">{{translates[curLang].text11}}</p>
            </div>
        </div>
    </div>
</div>
<div class="pick-fund" style="background-color: #fff;">
    <div class="content-wrapper">
        <div class="funds">
            <div class="fund-item grey" style="background: #fafafc">
                <img src="../../assets/individual/dollar.png" alt="" class="fund-img">
                <div class="top-info">
                    <h2 class="top-title">{{translates[curLang].text12}}</h2>
                    <p class="top-text">{{translates[curLang].text13}}</p>
                </div>
                <div class="top-info" style="min-height: 0;">
                    <h2 class="top-title" style="font-weight: 500;">{{translates[curLang].text14}} 50 000₽</h2>
                    <p class="top-text">{{translates[curLang].text15}}</p>
                </div>
                <div class="middle-info">
                    <div class="info">
                        
                        <h2>1%</h2>
                        <p class="middle-text">{{translates[curLang].text16}}</p>
                    </div>
                    <div class="info">
                        
                        <h2>0,08%</h2>
                        <p class="middle-text">{{translates[curLang].text17}}</p>
                    </div>
                    <div class="info">
                        
                        <h2>13%</h2>
                        <p class="middle-text">{{translates[curLang].text18}}</p>
                    </div>
                    
                </div>
                <div class="controls">
                    <button class="green-button tranparent-btn control" (click)="pay()">{{translates[curLang].text19}}</button>
                </div>
            </div>

            <div class="fund-item grey" style="background: #fafafc">
                <img src="../../assets/individual/safe.png" alt="" class="fund-img">
                <div class="top-info">
                    <h2 class="top-title">{{translates[curLang].text20}}</h2>
                    <p class="top-text">{{translates[curLang].text21}}</p>
                </div>
                <div class="top-info" style="min-height: 0;">
                    <h2 class="top-title" style="font-weight: 500;">{{translates[curLang].text14}} 30 000₽</h2>
                    <p class="top-text">{{translates[curLang].text22}}</p>
                </div>
                <div class="middle-info">
                    <div class="info">
                        
                        <h2>4%</h2>
                        <p class="middle-text">{{translates[curLang].text16}}</p>
                    </div>
                    <div class="info">
                        
                        <h2>0,2%</h2>
                        <p class="middle-text">{{translates[curLang].text17}}</p>
                    </div>
                    <div class="info">
                        
                        <h2>13%</h2>
                        <p class="middle-text">{{translates[curLang].text18}}</p>
                    </div>
                    
                </div>
                <div class="controls">
                    <button class="green-button tranparent-btn control" (click)="pay()">{{translates[curLang].text19}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="features">
    <div class="content-wrapper">
        <div class="features-header">
            <div class="title-wrapper">
                <h2>{{translates[curLang].text23}}</h2>
            </div>
            <div class="text-wrapper">
                <img src="../../../assets/individual/gerb.svg" width="30px" height="30px" alt="">
                <span>{{translates[curLang].text24}}</span>
            </div>
        </div>
        <app-reasons 
            [title1]="translates[curLang].text25" 
            [text1]="translates[curLang].text26" 
            [img1]="'/individual/1.svg'"
            [title2]="translates[curLang].text27" 
            [text2]="translates[curLang].text28"
            [img2]="'/individual/2.svg'"
            [title3]="translates[curLang].text29" 
            [text3]="translates[curLang].text30"
            [img3]="'/individual/3.svg'"
        ></app-reasons>
        <app-reasons 
            [title1]="translates[curLang].text31" 
            [text1]="translates[curLang].text32"
            [img1]="'/individual/4.svg'"
            [title2]="translates[curLang].text33" 
            [text2]="translates[curLang].text34"
            [img2]="'/individual/5.svg'"
            [title3]="translates[curLang].text35" 
            [text3]="translates[curLang].text36"
            [img3]="'/individual/6.svg'"
        ></app-reasons>
    </div>
</div>
<app-how-to-investor></app-how-to-investor>
<div class="instruction">
    <div class="content-wrapper">
        <div class="intruct-container">
            <div class="intruct-item" [ngClass]="{'active':info1}" (click)="showInfo1()">
                <div class="text" >
                    <span>{{translates[curLang].text37}}</span>
                    <p>{{translates[curLang].text38}}</p>
                </div>
                <div class="img-container">
                    <img src="../../../assets/header/arrow.svg"  alt="">
                </div>
            </div>

            <div class="intruct-item" [ngClass]="{'active':info2}" (click)="showInfo2()">
                <div class="text">
                    <span>{{translates[curLang].text39}}</span>
                    <p>{{translates[curLang].text40}}</p>
                </div>
                <div class="img-container">
                    <img src="../../../assets/header/arrow.svg"  alt="">
                </div>
            </div>

            <div class="intruct-item" [ngClass]="{'active':info3}" (click)="showInfo3()">
                <div class="text">
                    <span>{{translates[curLang].text41}}</span>
                    <p>{{translates[curLang].text42}}</p>
                </div>
                <div class="img-container">
                    <img src="../../../assets/header/arrow.svg"  alt="">
                </div>
            </div>
        </div>
    </div>
</div>
<app-question-block></app-question-block>
<div class="pick-fund">
    <div class="content-wrapper">
        <div class="funds">
            <div class="fund-item">
                <img src="../../assets/trading-accaunt/start.png" alt="" class="fund-img">
                <div class="top-info">
                    <h2 class="top-title">{{translates[curLang].text43}}</h2>
                    <p class="top-text">{{translates[curLang].text44}}</p>
                </div>
                <div class="middle-info">
                    <div class="info">
                        <h3>{{translates[curLang].text14}}</h3>
                        <h2>30 000₽</h2>
                        <p class="middle-text">{{translates[curLang].text45}}</p>
                    </div>
                    <div class="info">
                        
                        <h2>RUB</h2>
                        <p class="middle-text">{{translates[curLang].text46}}</p>
                    </div>
                    <div class="info">
                        <h3>{{translates[curLang].text14}}</h3>
                        <h2>0, 15%</h2>
                        <p class="middle-text">{{translates[curLang].text47}}</p>
                    </div>
                    
                </div>
            </div>

            <div class="fund-item" >
                <div class="top-info" style="min-height: 0;">
                    <h2 class="top-title">{{translates[curLang].text48}}</h2>
                </div>
                <div class="content-table">
                    <div class="table-header">
                        <div class="first-col" style="min-height: 0;">
                            <p>{{translates[curLang].text49}}</p>
                        </div>
                        <div class="sec-col" style="min-height: 0;">
                            <p>{{translates[curLang].text50}}</p>
                        </div>
                        <div class="third-col" style="min-height: 0;">
                            <p>{{translates[curLang].text51}}</p>
                        </div>
                    </div>
                    <div class="table-row">
                        <div class="first-col">
                            <p class="main-p">{{translates[curLang].text52}}</p>
                            <p class="pre-main">KOGK</p>
                        </div>
                        <div class="sec-col">
                            <p>{{translates[curLang].text53}}</p>
                        </div>
                        <div class="third-col">
                            <p class="main-p">200₽</p>
                        </div>
                    </div>
                    <div class="table-row">
                        <div class="first-col">
                            <p class="main-p">{{translates[curLang].text54}}</p>
                            <p class="pre-main">DZRDP</p>
                        </div>
                        <div class="sec-col">
                            <p>{{translates[curLang].text53}}</p>
                        </div>
                        <div class="third-col">
                            <p class="main-p">2 490₽</p>
                        </div>
                    </div>
                    <div class="table-row">
                        <div class="first-col">
                            <p class="main-p">{{translates[curLang].text55}}</p>
                            <p class="pre-main">MOEX</p>
                        </div>
                        <div class="sec-col">
                            <p>{{translates[curLang].text56}}</p>
                        </div>
                        <div class="third-col">
                            <p class="main-p">122,7₽</p>
                        </div>
                    </div>
                </div>
                
            </div>

        </div>
        
    </div>
</div>
<app-documents></app-documents>