<app-main-title
    [isBtn]="false"
    [title]="translates[curLang].text1"
    [text]="translates[curLang].text2"
    [img]="'/about-us/lazure.jpg'"
    [textColor]="'#fff'"
></app-main-title>
<div class="info">
    <div class="content-wrapper" style="max-width: 1000px;">
        <h2>{{translates[curLang].text3}}</h2>
        <p>{{translates[curLang].text4}}</p>
    </div>
</div>
<div class="info grey">
    <div class="content-wrapper" style="max-width: 1000px;">
        <h2>{{translates[curLang].text5}}</h2>
        <div class="two-info">
            <div class="item">
                <p class="title">{{translates[curLang].text6}}</p>
                <p class="text">{{translates[curLang].text7}}</p>
            </div>
            <div class="item">
                <p class="title">{{translates[curLang].text8}}</p>
                <p class="text">{{translates[curLang].text9}}</p>
            </div>
        </div>
    </div>
</div>
<div class="chart-block">
    <div class="content-wrapper" style="max-width: 1000px;">
        <h2>{{translates[curLang].text10}}</h2>
        <img src="../../../assets/about-us/chart.png" width="100%" alt="">
    </div>
</div>
<div class="info">
    <div class="content-wrapper" style="max-width: 1200px;">
        <h2>{{translates[curLang].text11}}</h2>
        <div class="two-info" style="border: none;">
            <div class="item">
                <p class="title">{{translates[curLang].text12}}</p>
                <p class="text">{{translates[curLang].text13}}</p>
            </div>
            <div class="item">
                <p class="title">{{translates[curLang].text14}}</p>
                <p class="text">{{translates[curLang].text15}}</p>
            </div>
        </div>
        <app-see-also-funds
                [title1]="translates[curLang].text16"
                [preTitle1]="translates[curLang].text17"
                [preNum1]="translates[curLang].text18"
                [Num1]="'20%'"
                [infoBottom1]="translates[curLang].text19"
                [preNum2]="''"
                [Num2]="'0,5%'"
                [infoBottom2]="translates[curLang].text20"
                [preNum3]="translates[curLang].text21"
                [Num3]="'30 000₽'"
                [infoBottom3]="translates[curLang].text22"
                [img1]="'/main/Platonics 1.png'"

                [title2]="translates[curLang].text23"
                [preTitle2]="translates[curLang].text24"
                [preNum4]="translates[curLang].text21"
                [Num4]="'0,25%'"
                [infoBottom4]="translates[curLang].text25"
                [preNum5]="translates[curLang].text18"
                [Num5]="'20%'"
                [infoBottom5]="translates[curLang].text26"
                [preNum6]="translates[curLang].text21"
                [Num6]="'100 000₽'"
                [infoBottom6]="translates[curLang].text27"
                [img2]="'/main/Anticetera_Machine 1.png'"

                [routerLink]="'/solutions/trust-strategies'"
        ></app-see-also-funds>
        <div class="button-wrapepr" style="text-align: center;">
            <button class="green-button" style="width: fit-content; margin-top: 30px;" (click)="openDialog()">{{translates[curLang].text41}}</button>
        </div>

        <div class="two-info" style="border: none;">
            <div class="item" style="width: 100%;">
                <p class="title">{{translates[curLang].text28}}</p>
                <p class="text">{{translates[curLang].text29}}</p>
            </div>
            
        </div>
        <app-see-also-funds
            [title1]="translates[curLang].text30"
            [preTitle1]="translates[curLang].text31"
            [preNum1]="''"
            [Num1]="translates[curLang].text32"
            [infoBottom1]="translates[curLang].text33"
            [preNum2]="''"
            [Num2]="'0,15%'"
            [infoBottom2]="translates[curLang].text34"
            [preNum3]="translates[curLang].text21"
            [Num3]="'30 000₽'"
            [infoBottom3]="translates[curLang].text35"
            [img1]="'/main/Start_Simple_Final 2.png'"

            [title2]="translates[curLang].text36"
            [preTitle2]="translates[curLang].text37"
            [preNum4]="''"
            [Num4]="'4%'"
            [infoBottom4]="translates[curLang].text38"
            [preNum5]="translates[curLang].text18"
            [Num5]="'0,2%'"
            [infoBottom5]="translates[curLang].text39"
            [preNum6]="translates[curLang].text21"
            [Num6]="'30 000₽'"
            [infoBottom6]="translates[curLang].text40"
            [img2]="'/main/Safe_FINAL 1.png'"
            
            [routerLink]="'/solutions/du-iis'"
        ></app-see-also-funds>
        <div class="button-wrapepr" style="text-align: center;">
            <button class="green-button" style="width: fit-content; margin-top: 30px;" (click)="openDialog()">{{translates[curLang].text41}}</button>
        </div>
    </div>
</div>