import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-strategies',
  templateUrl: './strategies.component.html',
  styleUrls: ['./strategies.component.scss']
})
export class StrategiesComponent {
  @Input() title: string
  @Input() img: string
  @Input() text: string

}
