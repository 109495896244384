import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as langEn from './locale_en.json'
import * as langRu from './locale_ru.json'
import { FormService, Translate } from 'src/app/services/form.service';
import { MatDialog } from '@angular/material/dialog';
import { FormDialogComponent } from './UI/form-dialog/form-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'merlin-linch';
  menuIsOpen = false;

  curLang = "en"
  en = langEn
  ru = langRu
  translates: Translate = {
    "en": this.en.en,
    "ru": this.ru.ru,
  }

  constructor(router:Router, formService:FormService, private dialog:MatDialog){
    router.events.subscribe((ev)=>{
      if(ev instanceof NavigationEnd){
        window.scrollTo(0,0)
      }
    })
    formService.curLang.subscribe(res => this.curLang = res!)
  }

  openMenu(){
    this.menuIsOpen = this.menuIsOpen === false ? true : false
    if(this.menuIsOpen){
      document.getElementById("menu")?.classList.add('visible')
    }else{
      document.getElementById("menu")?.classList.remove('visible')
    }
    
  }

  openDialog(){
    const dialogRef = this.dialog.open(FormDialogComponent);
  }

  goTelegram(){
    window.open('https://t.me/IK_SAV_Capital','_blank') 
  }

  goWhatsApp(){
    window.open('https://wa.me/447958113861','_blank') 
  }
  goTgChat(){
    //window.open('https://t.me/Merrill_Lynch1','_blank') 
  }
}
