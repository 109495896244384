<div class="content-wrapper">
  <div class="mainBlock">
    <h1>{{translates[curLang].text1}}</h1>
    <div class="cards">
      <div class="card">
        <div class="">
          <p class="number">01.</p>
        </div>
        <div class="info">
          <h2>{{translates[curLang].text2}}</h2>
          <p>{{translates[curLang].text3}}</p>
          <button class="green-button tranparent-btn" (click)="pay()">{{translates[curLang].text4}}</button>
        </div>
      </div>
      <div class="card">
        <div class="">
          <p class="number">02.</p>
        </div>
        <div class="info">
          <h2>{{translates[curLang].text5}}</h2>
          <p>{{translates[curLang].text6}}</p>
          <button class="green-button tranparent-btn sec-btn" (click)="pay()">{{translates[curLang].text7}}</button>
        </div>
      </div>
      <div class="card">
        <div class="">
          <p class="number">03.</p>
        </div>
        <div class="info">
          <h2>{{translates[curLang].text8}}</h2>
          <div class="img-wrapper">
            <!-- <div class="cabinet" (click)="pay()">
              <div class="img">
                <img src="../../../assets/myCab.svg" alt="">
              </div>
              <div class="text">
                
                <p class="main-text">{{translates[curLang].text9}}</p>
              </div>
            </div> -->
            <button class="green-button tranparent-btn" style="margin-top: 7px;" (click)="pay()">{{translates[curLang].text9}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

