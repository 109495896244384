import {Component, Injectable, Input} from '@angular/core';

@Component({
  selector: 'app-cost-dynamic',
  templateUrl: './cost-dynamic.component.html',
  styleUrls: ['./cost-dynamic.component.scss']
})
export class CostDynamicComponent {
  @Input() percent: string
  @Input() img: string
}
