import {Component, Input} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormDialogComponent } from 'src/app/UI/form-dialog/form-dialog.component';
import { FormService } from 'src/app/services/form.service';

@Component({
  selector: 'app-main-title',
  templateUrl: './main-title.component.html',
  styleUrls: ['./main-title.component.scss']
})
export class MainTitleComponent {
  @Input() title: string
  @Input() img: string
  @Input() text: string
  @Input() text2: string
  @Input() text3: string
  @Input() btnText:string
  @Input() controlText1:string
  @Input() controlText2:string
  @Input() showControls:boolean = false
  @Input() link1:string
  @Input() link2:string
  @Input() textColor:string = '#fff'
  @Input() isBtn:boolean = true
  @Input() isPay = false

  constructor(private formService:FormService, public dialog: MatDialog){}

  openDialog(){
    const dialogRef = this.dialog.open(FormDialogComponent);
  }

  pay(){
    window.open('https://workspace.investments-today.online/','_blank') 
  }
}
