<app-main-title
    [title]="translates[curLang].text1"
    [text]="translates[curLang].text2"
    [text2]="translates[curLang].text3"
    [text3]="translates[curLang].text4"
    [btnText]="translates[curLang].text5"
    [img]="'/about-us/work.jpg'"
    [textColor]="'#fff'"
></app-main-title>
<div class="advantages">
    <div class="content-wrapper">
        <h2 class="adv-title">{{translates[curLang].text6}}</h2>
        <div class="adv-container">
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text7}}</h2>
                <p class="item-text">{{translates[curLang].text8}}</p>
            </div>
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text9}}</h2>
                <p class="item-text">{{translates[curLang].text10}}</p>
            </div>
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text11}}</h2>
                <p class="item-text">{{translates[curLang].text12}}</p>
            </div>
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text13}}</h2>
                <p class="item-text">{{translates[curLang].text14}}</p>
            </div>
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text15}}</h2>
                <p class="item-text">{{translates[curLang].text16}}</p>
            </div>
        </div>
    </div>
</div>
<div class="advice-list">
    <div class="content-wrapper">
        <div class="items-wrapper">
            <div class="item" style="padding-top:40px ;">
                <h2>{{translates[curLang].text17}}</h2>
                
                <button class="green-button" style="width: fit-content; margin-top: 30px;" (click)="openDialog()">{{translates[curLang].text18}}</button>
            </div>
            <div class="item" style="text-align: center;">
                <img src="../../../assets/about-us/building.png" width="70%" alt="">
            </div>
            <div class="item">
                <div class="list">
                    <div class="list-item">
                        <span class="number">01.</span>
                        <span class="text">{{translates[curLang].text19}}</span>
                    </div>
                    <div class="list-item">
                        <span class="number">02.</span>
                        <span class="text">{{translates[curLang].text20}}</span>
                    </div>
                    <div class="list-item">
                        <span class="number">03.</span>
                        <span class="text">{{translates[curLang].text21}}</span>
                    </div>
                    <div class="list-item">
                        <span class="number">04.</span>
                        <span class="text">{{translates[curLang].text22}}</span>
                    </div>
                        <div class="list-item">
                            <span class="number">05.</span>
                            <span class="text">{{translates[curLang].text23}}</span>
                        </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
