<div class="trade-nav">
    <div class="content-wrapper" style="display: flex;">
        <div class="nav-item" (mouseover)="overNav(1)" (mouseleave)="leaveNav(1)">
            <p class="nav-text" [routerLink]="'/solutions/trust-strategies'">{{translates[curLang].text1}}</p>
            <div class="bottom-line" [@line]="lineState"></div>
        </div>
        <div class="nav-item"  (mouseover)="overNav(2)" (mouseleave)="leaveNav(2)">
            <p class="nav-text" [routerLink]="'/solutions/du-iis'">{{translates[curLang].text2}}</p>
            <div class="bottom-line" [@line]="lineState2"></div>
        </div>
    </div>
</div>
<app-main-title 
    [img]="'/du-iis/gears2.jpg'" 
    [title]="translates[curLang].text2" 
    [text]="translates[curLang].text3"
    [btnText]="'Заказать консультацию'"

    [textColor]="'#000'"
></app-main-title>
<div class="advantages">
    <div class="content-wrapper">
        <div class="adv-container">
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text4}}</h2>
            </div>
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text5}}</h2>
                <p class="item-text">{{translates[curLang].text6}}</p>
            </div>
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text7}}</h2>
                <p class="item-text">{{translates[curLang].text8}}</p>
            </div>
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text9}}</h2>
                <p class="item-text">{{translates[curLang].text10}}</p>
            </div>
        </div>
    </div>
</div>

<div class="img-text-block">
    <div class="content-wrapper" style="display: flex;">
        <div class="img-container">
            <img src="../../../assets/trust-strategies/gears.png" alt="">
        </div>
        <div class="info-container">
            <h2>{{translates[curLang].text11}}</h2>
            <p>{{translates[curLang].text12}}</p>
            <button class="green-button" style="width: fit-content; margin-top: 30px;" (click)="openDialog()">{{translates[curLang].text13}}</button>
        </div>
    </div>
</div>
<div class="pick-fund" style="background-color: #fff;">
    <div class="content-wrapper">
        <div class="funds">
            <div class="fund-item grey" style="background: #fafafc">
                <div class="corner-info">
                    <h3>{{translates[curLang].text14}}</h3>
                    <p>{{translates[curLang].text15}}</p>
                </div>
                <img src="../../assets/du-iis/duiis.png" alt="" class="fund-img">
                <div class="top-info">
                    <h2 class="top-title">{{translates[curLang].text16}} - <br> {{translates[curLang].text17}}</h2>
                </div>
                
                <div class="middle-info">
                    <div class="info">
                        
                        <h2>{{translates[curLang].text18}} 100 000</h2>
                        <p class="middle-text">{{translates[curLang].text19}}</p>
                    </div>
                    <div class="info">
                        
                        <h2>1 000 000</h2>
                        <p class="middle-text">{{translates[curLang].text20}}</p>
                    </div>
                    <div class="info">
                        
                        <h2>13%</h2>
                        <p class="middle-text">{{translates[curLang].text21}}</p>
                    </div>
                    
                </div>
                <div class="controls">
                    <button class="green-button tranparent-btn control" style="width: fit-content;" (click)="openDialog()">{{translates[curLang].text22}}</button>
                </div>
            </div>

            <div class="fund-item grey" style="background: #fafafc">
                <div class="corner-info">
                    <h3>{{translates[curLang].text23}} 9%</h3>
                    <p>{{translates[curLang].text24}}</p>
                </div>
                <img src="../../assets/du-iis/iisdu.png" alt="" class="fund-img">
                <div class="top-info">
                    <h2 class="top-title">{{translates[curLang].text25}} - <br> {{translates[curLang].text26}}</h2>
                </div>
                
                <div class="middle-info">
                    <div class="info">
                        
                        <h2>{{translates[curLang].text18}} 100 000</h2>
                        <p class="middle-text">{{translates[curLang].text19}}</p>
                    </div>
                    <div class="info">
                        
                        <h2>1 000 000</h2>
                        <p class="middle-text">{{translates[curLang].text20}}</p>
                    </div>
                    <div class="info">
                        
                        <h2>13%</h2>
                        <p class="middle-text">{{translates[curLang].text21}}</p>
                    </div>
                    
                </div>
                <div class="controls">
                    <button class="green-button tranparent-btn control" style="width: fit-content;" (click)="openDialog()">{{translates[curLang].text22}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="features">
    <div class="content-wrapper">
        <div class="features-header">
            <div class="title-wrapper">
                <h2>{{translates[curLang].text27}}</h2>
            </div>
            <div class="text-wrapper">
                <img src="../../../assets/individual/gerb.svg" width="30px" height="30px" alt="">
                <span>{{translates[curLang].text28}}</span>
            </div>
        </div>
        <app-reasons 
            [title1]="translates[curLang].text29" 
            [text1]="translates[curLang].text30" 
            [img1]="'/individual/1.svg'"
            [title2]="translates[curLang].text31" 
            [text2]="translates[curLang].text32"
            [img2]="'/individual/2.svg'"
            [title3]="translates[curLang].text33" 
            [text3]="translates[curLang].text34"
            [img3]="'/individual/3.svg'"
        ></app-reasons>
        <app-reasons 
            [title1]="translates[curLang].text35" 
            [text1]="translates[curLang].text36"
            [img1]="'/individual/4.svg'"
            [title2]="translates[curLang].text37" 
            [text2]="translates[curLang].text38"
            [img2]="'/individual/5.svg'"
            [title3]="translates[curLang].text39" 
            [text3]="translates[curLang].text40"
            [img3]="'/individual/6.svg'"
        ></app-reasons>
    </div>
</div>

<app-how-to-investor></app-how-to-investor>

<div class="instruction">
    <div class="content-wrapper">
        <div class="intruct-container">

            <div class="intruct-item" [ngClass]="{'active':info1}" (click)="showInfo1()">
                <div class="text">
                    <span>{{translates[curLang].text41}}</span>
                    <p>{{translates[curLang].text42}}</p>
                </div>
                <div class="img-container">
                    <img src="../../../assets/header/arrow.svg"  alt="">
                </div>
            </div>

            <div class="intruct-item" [ngClass]="{'active':info2}" (click)="showInfo2()">
                <div class="text">
                    <span>{{translates[curLang].text43}}</span>
                    <p>- {{translates[curLang].text44}}</p>
                </div>
                <div class="img-container">
                    <img src="../../../assets/header/arrow.svg"  alt="">
                </div>
            </div>

            <div class="intruct-item" [ngClass]="{'active':info3}" (click)="showInfo3()">
                <div class="text">
                    <span>{{translates[curLang].text45}}</span>
                    <p>{{translates[curLang].text46}}
                        <br>{{translates[curLang].text47}} 
                        <br>{{translates[curLang].text48}}
                        <br>{{translates[curLang].text49}}
                         <br> -  {{translates[curLang].text50}}
                         <br> -  {{translates[curLang].text51}}
                         <br> -  {{translates[curLang].text52}}
                         <br>{{translates[curLang].text53}}
                         <br>{{translates[curLang].text54}}
                        <br>{{translates[curLang].text55}}
                        <br> -  {{translates[curLang].text56}}
                        <br> -  {{translates[curLang].text57}}
                        <br> -  {{translates[curLang].text58}}
                </div>
                <div class="img-container">
                    <img src="../../../assets/header/arrow.svg"  alt="">
                </div>
            </div>
        </div>
    </div>
</div>
<app-question-block></app-question-block>
<app-documents></app-documents>