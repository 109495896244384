<div class="container">
  <div class="cards">
    <div class="card">
      <h1 style="margin-bottom: 30px;">Стартовый портфель</h1>
      <p style="margin-bottom: 30px;">
        Удобный инструмент для тех, кто хочет начать инвестировать, но не готов выбирать ценные бумаги
        самостоятельно
      </p>
      <div class="little-card" style="margin-bottom: 40px;">
        <p style="margin-right: 30px;">Стоимость услуг</p>
        <div class="">
          <p style="font-weight:bold;">Первая ИИР* – в подарок!</p>
          <p style="font-size: 12px; color: gray">Последующие ИИР* – 500р каждая.</p>
        </div>
      </div>
      <div class="little-card" style="margin-bottom: 40px;">
        <p style="margin-right: 30px;">Комиссия брокера</p>
        <div class="">
          <p style="font-weight:bold;">0,15%</p>
          <p style="font-size: 12px; color: gray">от суммы Сделки</p>
        </div>
      </div>
      <button>Полные условия тарифа</button>
      <p style="font-size: 12px; color: gray">
        *ИИР - индивидуальная инвестиционная рекомендация <br><br>
        Меррилл Линч Секьюритиз имеет статус инвестиционного советника. Дата внесения в единый реестр инвестиционных советников -
        «29» апреля 2021 года.
      </p>
    </div>
    <div class="card" style="align-items: center;">
      <h1 style="margin-bottom: 30px;">Примеры портфелей</h1>
      <div class="buttonsBlock">
        <button class="portfelButton mr20 active" id="1" (click)="switchNumber(1)">Консервативный</button>
        <button class="portfelButton mr20" id="2" (click)="switchNumber(2)">Умеренный</button>
        <button class="portfelButton" id="3" (click)="switchNumber(3)">Активный</button>
      </div>
      <div class="imgBlock">
        <img *ngIf="diagram_number===1" src="/assets/portfel_diagram_1.png" alt="">
        <img *ngIf="diagram_number===2" src="/assets/portfel_diagram_2.png" alt="">
        <img *ngIf="diagram_number===3" src="/assets/portfel_diagram_3.png" alt="">
      </div>
    </div>
  </div>
</div>
