import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {MainComponent} from "./main/main.component";
import {TradingAccountComponent} from "./trading/trading-account/trading-account.component";
import {
  IndividualInvestmentAccountComponent
} from "./trading/individual-investment-account/individual-investment-account.component";
import {ModelPortfoliosComponent} from "./trading/model-portfolios/model-portfolios.component";
import {TrustStrategiesComponent} from "./solutions/trust-strategies/trust-strategies.component";
import {DuIisComponent} from "./solutions/du-iis/du-iis.component";
import {MonetaryComponent} from "./solutions/trust-strategies/monetary/monetary.component";
import {InvestIdeasComponent} from "./analytics/invest-ideas/invest-ideas.component";
import {InstrumentsComponent} from "./analytics/instruments/instruments.component";
import {AboutUsComponent} from "./about-us/about-us/about-us.component";
import {NewsComponent} from "./about-us/news/news.component";
import {LicenseComponent} from "./about-us/license/license.component";
import {ContactsComponent} from "./about-us/contacts/contacts.component";
import {WorkWithUsComponent} from "./about-us/work-with-us/work-with-us.component";
import { IndividualTrustStrategyComponent } from './trust/individual-trust-strategy/individual-trust-strategy.component';
import { InvestmentAdviserComponent } from './trust/investment-adviser/investment-adviser.component';
import { GeneralDocumentsComponent } from './documents/general-documents/general-documents.component';
import { ComplianceComponent } from './documents/compliance/compliance.component';
import { GeneralIssuesComponent } from './documents/general-issues/general-issues.component';

const routes: Routes = [
  {path:'', redirectTo:'home', pathMatch: "full"},
  {path:'home',component:MainComponent},

  {path:'trading/trading-account',component:TradingAccountComponent},
  {path:'trading/individual-investment-account',component:IndividualInvestmentAccountComponent},
  {path:'services/margin-trading',component:ModelPortfoliosComponent},

  {path:'solutions/trust-strategies',component:TrustStrategiesComponent},
  {path:'solutions/trust-strategies/monetary',component:MonetaryComponent},
  {path:'solutions/du-iis',component:DuIisComponent},

  {path:'trust/individual-trust-strategy', component:IndividualTrustStrategyComponent},
  {path:'trust/investment-adviser', component:InvestmentAdviserComponent},

  {path:'analytics/invest-ideas',component:InvestIdeasComponent},
  {path:'analytics/instruments',component:InstrumentsComponent},


  {path:'about-us/about-us',component:AboutUsComponent},
  {path:'about-us/news',component:NewsComponent},
  {path:'about-us/license',component:LicenseComponent},
  {path:'about-us/contacts',component:ContactsComponent},
  {path:'about-us/work-with-us',component:WorkWithUsComponent},

  {path:'documents/information/general-documents', component:GeneralDocumentsComponent},
  {path:'documents/information/compliance', component:ComplianceComponent},
  {path:'education-block/faq/general-issues', component:GeneralIssuesComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
