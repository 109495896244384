import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-key-fund-indicators',
  templateUrl: './key-fund-indicators.component.html',
  styleUrls: ['./key-fund-indicators.component.scss']
})
export class KeyFundIndicatorsComponent {
  @Input() start_summ: string
  @Input() img: string
  @Input() percent: string
  @Input() lvl: string
  @Input() year: string
}
