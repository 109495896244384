import { Component } from '@angular/core';

@Component({
  selector: 'app-consultation-modal',
  templateUrl: './consultation-modal.component.html',
  styleUrls: ['./consultation-modal.component.scss']
})
export class ConsultationModalComponent {

}
