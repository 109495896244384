import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormDialogComponent } from 'src/app/UI/form-dialog/form-dialog.component';
import { FormService } from 'src/app/services/form.service';
import * as langEn from './locale_en.json'
import * as langRu from './locale_ru.json'
import { Translate } from 'src/app/services/form.service';

@Component({
  selector: 'app-monetary',
  templateUrl: './monetary.component.html',
  styleUrls: ['./monetary.component.scss'],
  animations:[
    trigger("line", [
      state('start', style({width:'0%'})),
      state('end', style({width:'100%'})),

      transition('start <=> end', animate('.3s'))
    ])
  ]
})
export class MonetaryComponent {
  lineState = 'start'
  lineState2 = 'start'
  
  curLang = "en"
  en = langEn
  ru = langRu
  translates:Translate = {
    "en": this.en.en,
    "ru": this.ru.ru,
  }
  constructor(public dialog: MatDialog, private service:FormService){
    service.curLang.subscribe(res=> this.curLang = res!)
  }
  openDialog(){
    const dialogRef = this.dialog.open(FormDialogComponent);
  }

  overNav(num:number){
    if(num===1){
      this.lineState = 'end'
    }else{
      this.lineState2 = 'end'
    }
    
  } 
  leaveNav(num:number){
    if(num===1){
      this.lineState = 'start'
    }else{
      this.lineState2 = 'start'
    }
  } 

  info1 = false;
  info2 = false;
  info3 = false;
  
  showInfo1(){
    this.info1 = !this.info1
  }
  showInfo2(){
    this.info2 = !this.info2
  }
  showInfo3(){
    this.info3 = !this.info3
  }
}
