<div class="container">
  <h1>{{translates[curLang].text1}}</h1>
  <div class="cards">
    <div class="card" (click)="openPdf1()">
      <img src="/assets/doc.svg" alt="">
      <div class="">
        <p class="hoverable-text">{{translates[curLang].text2}}</p>
        <p style="font-size: 11px;">pdf</p>
      </div>
    </div>
    <div class="card" (click)="openPdf2()">
      <img src="/assets/doc.svg" alt="">
      <div class="">
        <p class="hoverable-text">{{translates[curLang].text3}}</p>
        <p style="font-size: 11px;">pdf</p>
      </div>
    </div>
  </div>
  <button class="green-button" [routerLink]="'/documents/information/general-documents'">{{translates[curLang].text4}}</button>
</div>

