<div class="see-container" [style]="{display: inRow ? 'flex':'block'}">
    <div class="see-wrapper" [style]="{'background-color': bColor ? bColor:'#fafafc'}">
        <h2 class="see-title">{{title1}}</h2>
        <p class="see-preTitle">{{preTitle1}}</p>
        <div class="see-info">
            <div class="info-item">
                <div class="info-top">
                    <span>{{preNum1}}</span>
                    <span class="info-num">{{Num1}}</span>
                </div>
                <p class="info-bottom">{{infoBottom1}}</p>
            </div>
            <div class="info-item">
                <div class="info-top">
                    <span>{{preNum2}}</span>
                    <span class="info-num">{{Num2}}</span>
                </div>
                <p class="info-bottom">{{infoBottom2}}</p>
            </div>
            <div class="info-item">
                <div class="info-top">
                    <span>{{preNum3}}</span>
                    <span class="info-num">{{Num3}}</span>
                </div>
                <p class="info-bottom">{{infoBottom3}}</p>
            </div>
        </div>
        <img [src]="'../../../assets'+img1" alt="">
    </div>

    <div class="see-wrapper" [style]="{'background-color': bColor ? bColor:'#fafafc'}">
        <h2 class="see-title">{{title2}}</h2>
        <p class="see-preTitle">{{preTitle2}}</p>
        <div class="see-info">
            <div class="info-item">
                <div class="info-top">
                    <span>{{preNum4}}</span>
                    <span class="info-num">{{Num4}}</span>
                </div>
                <p class="info-bottom">{{infoBottom4}}</p>
            </div>
            <div class="info-item">
                <div class="info-top">
                    <span>{{preNum5}}</span>
                    <span class="info-num">{{Num5}}</span>
                </div>
                <p class="info-bottom">{{infoBottom5}}</p>
            </div>
            <div class="info-item">
                <div class="info-top">
                    <span>{{preNum6}}</span>
                    <span class="info-num">{{Num6}}</span>
                </div>
                <p class="info-bottom">{{infoBottom6}}</p>
            </div>
        </div>
        <img [src]="'../../../assets'+img2" alt="">
    </div>


</div>

