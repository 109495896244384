<div class="trade-nav">
    <div class="content-wrapper" style="display: flex;">
        <div class="nav-item" (mouseover)="overNav(1)" (mouseleave)="leaveNav(1)">
            <p class="nav-text" [routerLink]="'/trust/individual-trust-strategy'">{{translates[curLang].text1}}</p>
            <div class="bottom-line" [@line]="lineState"></div>
        </div>
        <div class="nav-item"  (mouseover)="overNav(2)" (mouseleave)="leaveNav(2)">
            <p class="nav-text" [routerLink]="'/trust/investment-adviser'">{{translates[curLang].text2}}</p>
            <div class="bottom-line" [@line]="lineState2"></div>
        </div>
    </div>
</div>
<app-main-title [img]="'/individual-trust-strategy/chessgray2.jpg'" 
    [title]="translates[curLang].text3" 
    [text]="translates[curLang].text4"
    [btnText]="translates[curLang].text5"></app-main-title>

<div class="advantages">
    <div class="content-wrapper">
        <h2 class="adv-title">{{translates[curLang].text6}}</h2>
        <div class="adv-container">
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text7}}</h2>
                <p class="item-text">{{translates[curLang].text8}}</p>
            </div>
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text9}}</h2>
                <p class="item-text">{{translates[curLang].text10}}</p>
            </div>
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text11}}</h2>
                <p class="item-text">{{translates[curLang].text12}}</p>
            </div>
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text13}}</h2>
                <p class="item-text">{{translates[curLang].text14}}</p>
            </div>
        </div>
    </div>
</div>
<div class="img-text-block">
    <div class="content-wrapper" style="display: flex;">
        <div class="img-container">
            <img src="../../../assets/individual-trust-strategy/chess1.png" alt="">
        </div>
        <div class="info-container">
            <h2>{{translates[curLang].text15}}</h2>
            <p>{{translates[curLang].text16}}</p>
            <button class="green-button" style="margin-top: 40px; width: fit-content;" (click)="openDialog()">{{translates[curLang].text17}}</button>
        </div>
    </div>
</div>
<div class="see-also">
    <h2>{{translates[curLang].text18}}</h2>
    <app-see-also-funds
    [title1]="translates[curLang].text19"
    [preTitle1]="translates[curLang].text20"
    [preNum1]="''"
    [Num1]="'20%'"
    [infoBottom1]="translates[curLang].text21"
    [preNum2]="translates[curLang].text26"
    [Num2]="'0,5%'"
    [infoBottom2]="translates[curLang].text22"
    [preNum3]="translates[curLang].text27"
    [Num3]="'30 000₽'"
    [infoBottom3]="translates[curLang].text23"
    [img1]="'/main/Platonics 1.png'"

    [title2]="translates[curLang].text24"
    [preTitle2]="translates[curLang].text25"
    [preNum4]="translates[curLang].text27"
    [Num4]="'0,25%'"
    [infoBottom4]="translates[curLang].text28"
    [preNum5]="translates[curLang].text26"
    [Num5]="'20%'"
    [infoBottom5]="translates[curLang].text29"
    [preNum6]="translates[curLang].text27"
    [Num6]="'100 000₽'"
    [infoBottom6]="translates[curLang].text30"
    [img2]="'/main/Anticetera_Machine 1.png'"

    [routerLink]="'/solutions/trust-strategies'"
></app-see-also-funds>
</div>

