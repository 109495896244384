<div class="shadow-block"></div>
<div class="contact-header">
    <div class="content-wrapper">
        <div class="content">
            <div class="content-col">
                <h2>{{translates[curLang].text1}}</h2>
            </div>
            <div class="content-col">
                <button>{{translates[curLang].text2}}</button>
            </div>
            <div class="content-col mail">
                <p>{{translates[curLang].text3}}</p>
                <div class="mail-container">
                    <img src="../../../assets/mail.svg" alt="">
                    <span>info@ic-savcapital.net</span>
                </div>
            </div>
            <div class="content-col phone" style="display: flex; align-items: center;">
                <div class="phone-container" >
                    <img src="../../../assets/header/phone.svg" alt="">
                    <span>+79295058582</span>
                </div>
            </div>
            <div class="content-col search">
                <div class="search-block">
                    <input type="search" placeholder="Поиск">
                    <img src="../../../assets/search.svg" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="contact-map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2244.7773830341102!2d37.61467137701401!3d55.76236929125403!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46b54a4364d3bbd1%3A0xbcfb9dfc8e4a99a9!2z0J_QtdGC0YDQvtCy0LrQsCDRg9C7LiwgNywg0JzQvtGB0LrQstCwLCDQoNC-0YHRgdC40Y8sIDEyNzA1MQ!5e0!3m2!1sru!2sua!4v1687348352975!5m2!1sru!2sua" width="100%" height="600px" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    <div class="info-block">
        <p class="adress">{{translates[curLang].text4}}</p>
    </div>
    
</div>