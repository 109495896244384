import { Component } from '@angular/core';
import * as langEn from './locale_en.json'
import * as langRu from './locale_ru.json'
import { FormService, Translate } from 'src/app/services/form.service';

@Component({
  selector: 'app-instruments',
  templateUrl: './instruments.component.html',
  styleUrls: ['./instruments.component.scss']
})
export class InstrumentsComponent {
  isCalendar = true;
  isProfile = false;
  isAnalise = false;
  isAnalise2 = false;

  showCalendar(){
    this.isCalendar = true;
    this.isProfile = false;
    this.isAnalise = false;
    this.isAnalise2 = false;
  }
  showProfile(){
    this.isCalendar = false;
    this.isProfile = true;
    this.isAnalise = false;
    this.isAnalise2 = false;
  }
  showAnalise(){
    this.isCalendar = false;
    this.isProfile = false;
    this.isAnalise = true;
    this.isAnalise2 = false;
  }
  showAnalise2(){
    this.isCalendar = false;
    this.isProfile = false;
    this.isAnalise = false;
    this.isAnalise2 = true;
  }

  curLang = "en"
  en = langEn
  ru = langRu
  translates:Translate = {
    "en": this.en.en,
    "ru": this.ru.ru,
  }
  constructor(private service:FormService){
    service.curLang.subscribe(res=> this.curLang = res!)
  }

}
