<!-- <div class="form-wrapepr">
    <h2 style="text-align: center; margin-bottom: 15px;">{{translates[curLang].text1}}</h2>
    <form action="" [formGroup]="infoForm">
        <mat-form-field class="example-full-width" style="width: 100%;">
            <mat-label>{{translates[curLang].text2}}</mat-label>
            <input type="email" matInput [formControlName]="'name'" >
          </mat-form-field>
          <br>
        <mat-form-field class="example-full-width" style="width: 100%;">
            <mat-label>{{translates[curLang].text3}}</mat-label>
            <input type="email" matInput [formControlName]="'phone'" >
          </mat-form-field>
    </form>
    <button (click)="sendForm()" [disabled]="infoForm.invalid" class="green-button" style="width: fit-content; margin-top: 15px; text-align: center; width: 100%;">{{translates[curLang].text4}}</button>
</div> -->

<div class="container">
  <form class="form" [formGroup]="infoForm">
    <div class="descr">{{translates[curLang].text1}}</div>
    <div class="input">
        <input [formControlName]="'name'" required="" autocomplete="off" type="text">
        <label for="name">Name</label>
    </div>

    <div class="input">
        <input [formControlName]="'phone'" required="" autocomplete="off" name="email" type="text">
        <label for="email">Phone</label>
    </div>

    <div class="input">
        <textarea required="" cols="30" rows="1" id="message"></textarea>
        <label for="message">Message</label>
    </div>
    <button (click)="sendForm()" [disabled]="infoForm.invalid">Send message →</button>
  </form>
</div>
