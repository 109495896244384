<div class="main-header">
    <div class="content-wrapper" style="height: 100%;">
        <div class="content">
            <h2>{{translates[curLang].text1}}</h2>
            <p class="pre-title">{{translates[curLang].text2}}</p>
            <div class="cost-block">
                <div class="item">
                    <p>{{translates[curLang].text3}}</p>
                </div>
                <div class="item">
                    <p class="number">{{translates[curLang].text4}} 15%</p>
                    <span>{{translates[curLang].text5}}</span>
                </div>
            </div>

            <button class="green-button" (click)="pay()">{{translates[curLang].text6}}</button>
        </div>
    </div>
</div>
<app-reasons [title1]="translates[curLang].text7" [img1]="'/margin-trading/1.svg'"
    [title2]="translates[curLang].text8" [img2]="'/margin-trading/2.svg'"
    [title3]="translates[curLang].text9" [img3]="'/margin-trading/3.svg'"></app-reasons>

<div class="how-it-works">

    <div class="content-wrapper">
        <h2>{{translates[curLang].text10}}</h2>
        <section>
            <div class="blocks">
                <div class="mk-middle item">
                    <div class="blocks-container">
                        <div class="img-container">
                            <img src="../../../assets/trading-accaunt/5blocks.svg" width="91px" height="80px" alt="">
                        </div>
                        <h2 class="big-num">500 000 ₽</h2>
                        <p class="small-text">{{translates[curLang].text11}}</p>
                    </div>

                </div>
                <div class="mk-right item">
                    <div class="first r-item">
                        <div class="blocks-container">
                            <div class="img-container">
                                <img src="../../../assets/trading-accaunt/1block.svg" width="91px" height="80px" alt="">
                            </div>
                            <h2 class="big-num">100 000 ₽</h2>
                            <p class="small-text">{{translates[curLang].text12}}</p>
                        </div>
                    </div>
                    <div class="seccond r-item">
                        <div class="simbol">+</div>
                    </div>
                    <div class="end r-item">
                        <div class="blocks-container">
                            <div class="img-container">
                                <img src="../../../assets/trading-accaunt/4blocks.svg" width="91px" height="80px"
                                    alt="">
                            </div>
                            <h2 class="big-num">400 000 ₽</h2>
                            <p class="small-text">{{translates[curLang].text13}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="num-text">
                <div class="num-wrapper">
                    <img src="../../../assets/margin-trading/one.png" alt="">
                </div>
                <div class="info-wrapper">
                    <h2>{{translates[curLang].text14}}</h2>
                    <p>{{translates[curLang].text15}}</p>
                </div>
            </div>
        </section>
        <section class="bottom">
            <div class="blocks">
                <div class="section">
                    <div class="text">
                        <p class="head-title">{{translates[curLang].text16}}</p>
                        <div class="info">
                            <p class="small-text">{{translates[curLang].text17}}</p>
                            <p class="cost">1 000₽</p>
                        </div>
                        <div class="info">
                            <p class="cost">400 {{translates[curLang].text18}}</p>
                            <p class="small-text">{{translates[curLang].text19}}</p>
                        </div>
                        <div class="info">
                            <p class="cost">400 000₽</p>
                            <p class="small-text">{{translates[curLang].text20}}</p>
                        </div>
                    </div>
                    <div class="image">
                        <img src="../../../assets/trading-accaunt/5blocks.svg" width="91px" height="80px" alt="">
                    </div>
                </div>
                <div class="section">
                    <div class="text">
                        <p class="head-title">{{translates[curLang].text21}}</p>
                        <div class="info">
                            <p class="small-text">{{translates[curLang].text22}}</p>
                            <p class="cost">750₽</p>
                        </div>
                        <div class="info">
                            <p class="cost">300 000₽</p>
                            <p class="small-text">400 {{translates[curLang].text18}}</p>
                        </div>
                        <div class="info">
                            <p class="cost">100 000₽</p>
                            <p class="small-text">{{translates[curLang].text23}}</p>
                        </div>
                    </div>
                    <div class="image">
                        <img src="../../../assets/margin-trading/money.svg" width="108px" height="80px" alt="">
                    </div>
                </div>
            </div>
            <div class="num-text">
                <div class="num-wrapper">
                    <img src="../../../assets/margin-trading/two.png" alt="">
                </div>
                <div class="info-wrapper">
                    <h2>{{translates[curLang].text24}}</h2>
                </div>
            </div>
        </section>
    </div>
</div>
<div class="zerofee-block">
    <div class="content-wrapper">
        <div class="zerofee">
            <div class="image">
                <img src="../../../assets/margin-trading/zerofee.png" alt="">
            </div>
            <div class="text">
                <h2>{{translates[curLang].text25}}</h2>
                <p>{{translates[curLang].text26}}</p>
            </div>
        </div>
    </div>
</div>
<div class="exchange">
    <div class="content-wrapper">
        <div class="title block">
            <h2>{{translates[curLang].text27}}</h2>
        </div>
        <div class="two-ex block">
            <div class="ex-item">
                <div class="logo">
                    <img src="../../../assets/margin-trading/mosbirja.svg"  alt="">
                </div>
                <p class="percent">{{translates[curLang].text4}} 15%</p>
                <p class="text">{{translates[curLang].text28}}</p>
                <button class="green-button tranparent-btn">{{translates[curLang].text29}}</button>
            </div>
            <div class="ex-item">
                <div class="logo">
                    <img src="../../../assets/margin-trading/spbbirja.png" alt="">
                </div>
                <p class="percent">15%</p>
                <p class="text">{{translates[curLang].text30}}</p>
                <button class="green-button tranparent-btn">{{translates[curLang].text31}}</button>
            </div>
        </div>
    </div>
</div>
<div class="info-list">
    <div class="content-wrapper">
        <h2>{{translates[curLang].text32}}</h2>
        <div class="list">
            <p>— {{translates[curLang].text33}}</p>
            <p>— {{translates[curLang].text34}}</p>
            <p>— {{translates[curLang].text35}}</p>
        </div>
    </div>
</div>
<app-how-to-investor></app-how-to-investor>
<app-question-block></app-question-block>