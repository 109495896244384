import { Component } from '@angular/core';
import * as langEn from './locale_en.json'
import * as langRu from './locale_ru.json'
import { FormService, Translate } from 'src/app/services/form.service';

@Component({
  selector: 'app-general-issues',
  templateUrl: './general-issues.component.html',
  styleUrls: ['./general-issues.component.scss']
})
export class GeneralIssuesComponent {
  goTelegram(){
    window.open('https://t.me/IK_SAV_Capital','_blank') 
  }

  curLang = "en"
  en = langEn
  ru = langRu
  translates:Translate = {
    "en": this.en.en,
    "ru": this.ru.ru,
  }
  constructor(private service:FormService){
    service.curLang.subscribe(res=> this.curLang = res!)
  }
}
