import { Component } from '@angular/core';
import * as langEn from './locale_en.json'
import * as langRu from './locale_ru.json'
import { FormService, Translate } from 'src/app/services/form.service';

@Component({
  selector: 'app-compliance',
  templateUrl: './compliance.component.html',
  styleUrls: ['./compliance.component.scss']
})
export class ComplianceComponent {
  openPdf1(){
    window.open('/assets/pdfs/ДОГОВОРНАВЕДЕНИЕИНДИВИДУАЛЬНОИНВЕСТИЦИОННОГОСЧЕТА.pdf','_blank') 
  }

  curLang = "en"
  en = langEn
  ru = langRu
  translates:Translate = {
    "en": this.en.en,
    "ru": this.ru.ru,
  }
  constructor(private service:FormService){
    service.curLang.subscribe(res=> this.curLang = res!)
  }
}
