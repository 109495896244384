import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormDialogComponent } from 'src/app/UI/form-dialog/form-dialog.component';
import * as langEn from './locale_en.json'
import * as langRu from './locale_ru.json'
import { FormService, Translate } from 'src/app/services/form.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent {
  curLang = "en"
  en = langEn
  ru = langRu
  translates:Translate = {
    "en": this.en.en,
    "ru": this.ru.ru,
  }
  constructor(public dialog: MatDialog, private service:FormService){
    service.curLang.subscribe(res=> this.curLang = res!)
  }

  openDialog(){
    const dialogRef = this.dialog.open(FormDialogComponent);
  }
}
