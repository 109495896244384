<div class="trade-nav">
    <div class="content-wrapper" style="display: flex;">
        <div class="nav-item" (mouseover)="overNav(1)" (mouseleave)="leaveNav(1)">
            <p class="nav-text" [routerLink]="'/trust/individual-trust-strategy'">{{translates[curLang].text1}}</p>
            <div class="bottom-line" [@line]="lineState"></div>
        </div>
        <div class="nav-item"  (mouseover)="overNav(2)" (mouseleave)="leaveNav(2)">
            <p class="nav-text" [routerLink]="'/trust/investment-adviser'">{{translates[curLang].text2}}</p>
            <div class="bottom-line" [@line]="lineState2"></div>
        </div>
    </div>
</div>
<app-main-title [img]="'/investment-adviser/sov2.jpg'" [title]="translates[curLang].text3" [text]="translates[curLang].text4"
    [btnText]="translates[curLang].text5"></app-main-title>
<div class="reasons-block">
    <div class="content-wrapper">
        <app-reasons [title1]="translates[curLang].text6" [img1]="'/investment-adviser/1.svg'"
            [title2]="translates[curLang].text7" [img2]="'/investment-adviser/2.svg'"
            [title3]="translates[curLang].text8" [img3]="'/investment-adviser/3.svg'"
        ></app-reasons>
        <app-reasons [title1]="translates[curLang].text9" [img1]="'/investment-adviser/4.svg'"
            [title3]="translates[curLang].text10" [img3]="'/investment-adviser/5.svg'"
        ></app-reasons>
    </div>
</div>    
<div class="advice-list">
    <div class="content-wrapper">
        <div class="items-wrapper">
            <div class="item">
                <h2>{{translates[curLang].text11}}</h2>
                <h3>{{translates[curLang].text12}}</h3>
                <button class="green-button" style="width: fit-content;" (click)="openDialog()">{{translates[curLang].text13}}</button>
            </div>
            <div class="item" style="text-align: center;">
                <img src="../../../assets/investment-adviser/quest.png" width="70%" alt="">
            </div>
            <div class="item">
                <div class="list">
                    <div class="list-item">
                        <span class="number">01.</span>
                        <span class="text">{{translates[curLang].text14}}</span>
                    </div>
                    <div class="list-item">
                        <span class="number">02.</span>
                        <span class="text">{{translates[curLang].text15}}</span>
                    </div>
                    <div class="list-item">
                        <span class="number">03.</span>
                        <span class="text">{{translates[curLang].text16}}</span>
                    </div>
                    <div class="list-item">
                        <span class="number">04.</span>
                        <span class="text">{{translates[curLang].text17}}</span>
                    </div>
                        <div class="list-item">
                            <span class="number">05.</span>
                            <span class="text">{{translates[curLang].text18}}</span>
                        </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<div class="advantages">
    <div class="content-wrapper">
        <h2 class="adv-title">{{translates[curLang].text19}}</h2>
        <div class="adv-container">
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text20}}</h2>
                <p class="item-text">{{translates[curLang].text21}}</p>
            </div>
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text22}}</h2>
                <p class="item-text">{{translates[curLang].text23}}</p>
            </div>
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text24}}</h2>
                <p class="item-text">{{translates[curLang].text25}}</p>
            </div>
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text26}}</h2>
                <p class="item-text">{{translates[curLang].text27}}</p>
            </div>
            <div class="adv-item">
                <h2 class="item-title">{{translates[curLang].text28}}</h2>
                <p class="item-text">{{translates[curLang].text29}}</p>
            </div>
        </div>
    </div>
</div>
<div class="grey-block">
    <div class="content-wrapper" style="position: relative;">
        <h2>{{translates[curLang].text30}}</h2>
        <div class="carusel-container">
            <div class="carusel" [@slider]="sliderState">
                <div class="carusel-item">
                   <div class="text">
                    <span>{{translates[curLang].text31}}</span>
                   </div>
                   <div class="img">
                        <img src="../../../assets/investment-adviser/arm.png" alt="">
                   </div>
                </div>

                <div class="carusel-item">
                    <div class="text">
                        <span>{{translates[curLang].text32}}</span>
                       </div>
                       <div class="img">
                            <img src="../../../assets/investment-adviser/phone.png" alt="">
                       </div>
                </div>

                <div class="carusel-item">
                    <div class="text">
                        <span>{{translates[curLang].text33}}</span>
                       </div>
                       <div class="img">
                            <img src="../../../assets/investment-adviser/watches.png" alt="">
                       </div>
                </div>

                <div class="carusel-item">
                    <div class="text">
                        <span>{{translates[curLang].text34}}</span>
                       </div>
                       <div class="img">
                            <img src="../../../assets/investment-adviser/bulb.png" alt="">
                       </div>
                </div>

                <div class="carusel-item">
                    <div class="text">
                        <span>{{translates[curLang].text35}}</span>
                       </div>
                       <div class="img">
                            <img src="../../../assets/investment-adviser/case.png" alt="">
                       </div>
                </div>
            </div>
            <div class="pagination">
                <div class="item" (click)="changeSlide(3)" [ngClass]="{'active':sliderCounter===0}"></div>
                <div class="item" (click)="changeSlide(0)" [ngClass]="{'active':sliderCounter===1}"></div>
                <div class="item" (click)="changeSlide(1)" [ngClass]="{'active':sliderCounter===2}"></div>
                <div class="item" (click)="changeSlide(2)" [ngClass]="{'active':sliderCounter===3}"></div>
            </div>
        </div>
        
    </div>
    <div class="btn-wrapper" style="text-align: center; margin-top: 60px;">
        <button class="green-button" style="width: fit-content;" (click)="openDialog()">{{translates[curLang].text36}}</button>
    </div>
    
</div>
<app-how-to-investor></app-how-to-investor>
<div class="instruction">
    <div class="content-wrapper">
        <div class="intruct-container">
            <div class="intruct-item" [ngClass]="{'active':info1}" (click)="showInfo1()">
                <div class="text" >
                    <span>{{translates[curLang].text37}}</span>
                    <p>{{translates[curLang].text38}} 
                        <br>{{translates[curLang].text39}}
                        <br>{{translates[curLang].text40}}</p>
                </div>
                <div class="img-container">
                    <img src="../../../assets/header/arrow.svg"  alt="">
                </div>
            </div>

            <div class="intruct-item" [ngClass]="{'active':info2}" (click)="showInfo2()">
                <div class="text">
                    <span>{{translates[curLang].text41}}</span>
                    <p>{{translates[curLang].text42}}</p>
                </div>
                <div class="img-container">
                    <img src="../../../assets/header/arrow.svg"  alt="">
                </div>
            </div>

            <div class="intruct-item" [ngClass]="{'active':info3}" (click)="showInfo3()">
                <div class="text">
                    <span>{{translates[curLang].text43}}</span>
                    <p>{{translates[curLang].text44}}</p>
                </div>
                <div class="img-container">
                    <img src="../../../assets/header/arrow.svg"  alt="">
                </div>
            </div>
        </div>
    </div>
</div>
<app-question-block></app-question-block>
<app-documents></app-documents>

  
