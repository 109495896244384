<div class="doc-wrapper">
    <div class="content-wrapper">
        <h2 class="doc-title">{{translates[curLang].text1}}</h2>
        <div class="buttons-container">
            <button class="doc-btn active">{{translates[curLang].text2}}</button>
            <button class="doc-btn" [routerLink]="'/documents/information/compliance'">{{translates[curLang].text3}}</button>
        </div>
        <div class="documents">
            <div class="doc-item" (click)="openPdf3()">
                <div class="item-image block">
                    <img src="../../../assets/doc.svg" alt="">
                </div>
                <div class="info block">
                    <p class="info-text">OOO-MLS-Brokerage-Rules_2018</p>
                    <div class="info-buttons">
                        <button class="doc-btn">{{translates[curLang].text4}}</button>
                        <button class="doc-btn active">{{translates[curLang].text5}}</button>
                    </div>
                    <p class="info-size">pdf - 62 Кб</p>
                </div>
            </div>
            <div class="doc-item" (click)="openPdf2()">
                <div class="item-image block">
                    <img src="../../../assets/doc.svg" alt="">
                </div>
                <div class="info block">
                    <p class="info-text">{{translates[curLang].text6}}</p>
                    <div class="info-buttons">
                        <button class="doc-btn">{{translates[curLang].text7}}</button>
                        <button class="doc-btn active">{{translates[curLang].text8}}</button>
                    </div>
                    <p class="info-size">pdf - 706 Кб</p>
                </div>
            </div>
            <div class="doc-item" (click)="openPdf1()">
                <div class="item-image block">
                    <img src="../../../assets/doc.svg" alt="">
                </div>
                <div class="info block">
                    <p class="info-text">{{translates[curLang].text9}}</p>
                    <div class="info-buttons">
                        <button class="doc-btn">{{translates[curLang].text10}}</button>
                        <button class="doc-btn active">{{translates[curLang].text11}}</button>
                    </div>
                    <p class="info-size">pdf - 898 Кб</p>
                </div>
            </div>
        </div>
    </div>

</div>