<div class="doc-wrapper">
    <div class="content-wrapper">
        <h2 class="doc-title">{{translates[curLang].text1}}</h2>
        <div class="buttons-container">
            <button class="doc-btn" [routerLink]="'/documents/information/general-documents'">{{translates[curLang].text2}}</button>
            <button class="doc-btn active">{{translates[curLang].text3}}</button>
        </div>
        <div class="big-info-block">
            <div class="complaens___gULXP">
                <p class="typo___oYDNK" style="font-size: 1.125rem; line-height: 1.44444;">{{translates[curLang].text4}}</p>
                <p class="typo___oYDNK" style="font-size: 1.125rem; line-height: 1.44444;">{{translates[curLang].text5}}</p>
                <p class="typo___oYDNK" style="font-size: 1.125rem; line-height: 1.44444;">{{translates[curLang].text6}}</p>
                <ul>
                    <li>
                        <p class="typo___oYDNK" style="font-size: 1.125rem; line-height: 1.44444;">{{translates[curLang].text7}}</p>
                    </li>
                    <li>
                        <p class="typo___oYDNK" style="font-size: 1.125rem; line-height: 1.44444;">{{translates[curLang].text8}}</p>
                    </li>
                    <li>
                        <p class="typo___oYDNK" style="font-size: 1.125rem; line-height: 1.44444;">{{translates[curLang].text9}}</p>
                    </li>
                    <li>
                        <p class="typo___oYDNK" style="font-size: 1.125rem; line-height: 1.44444;">{{translates[curLang].text10}}</p>
                    </li>
                </ul>
                <p class="typo___oYDNK" style="font-size: 1.125rem; line-height: 1.44444;">{{translates[curLang].text11}}</p>
                <ul>
                    <li>
                        <p class="typo___oYDNK" style="font-size: 1.125rem; line-height: 1.44444;">{{translates[curLang].text12}}</p>
                    </li>
                    <li>
                        <p class="typo___oYDNK" style="font-size: 1.125rem; line-height: 1.44444;">{{translates[curLang].text13}}</p>
                    </li>
                    <li>
                        <p class="typo___oYDNK" style="font-size: 1.125rem; line-height: 1.44444;">{{translates[curLang].text14}}</p>
                    </li>
                    <li>
                        <p class="typo___oYDNK" style="font-size: 1.125rem; line-height: 1.44444;">{{translates[curLang].text15}}</p>
                    </li>
                </ul>
                <p class="typo___oYDNK" style="font-size: 1.125rem; line-height: 1.44444;">{{translates[curLang].text16}}</p>
                
            </div>
        </div>
        <div class="documents">
            <div class="doc-item" (click)="openPdf1()">
                <div class="item-image block">
                    <img src="../../../assets/doc.svg" alt="">
                </div>
                <div class="info block">
                    <p class="info-text">{{translates[curLang].text17}}</p>
                    <div class="info-buttons">
                        <button class="doc-btn">{{translates[curLang].text18}}</button>
                        <button class="doc-btn active">{{translates[curLang].text19}}</button>
                    </div>
                    <p class="info-size">pdf - 898 Кб</p>
                </div>
            </div>
        </div>
    </div>

</div>