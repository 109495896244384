<div class="container" [style]="{'background-image': 'url(../../../assets'+img+')', 'background-size':'cover', ' background-position-x':'80%'}">
  <div class="controls" *ngIf="showControls">
    <button class="control" [routerLink]="link1">{{controlText1}}</button>
    <button class="control" [routerLink]="link2">{{controlText2}}</button>
  </div>
  <div class="titleInfo">
    <h1 [style]="{'color':textColor}">{{title}}</h1>
    <p style="padding: 20px 0;" [style]="{'color':textColor}">{{text}}</p>
    <p style="padding: 20px 0;" [style]="{'color':textColor}">{{text2}}</p>
    <p style="padding: 20px 0;" [style]="{'color':textColor}">{{text3}}</p>
    <button class="green-button" style="font-size: .9rem; width: fit-content;" *ngIf="isBtn"  (click)="isPay === false ? openDialog(): pay()">
      {{btnText}}
    </button>
  </div>
</div>
