import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface form {
  name: string,
  phone: string
}

export interface Translate {
  [key:string] :any
}

@Injectable({
  providedIn: 'root'
})
export class FormService {
  curLang = new BehaviorSubject(localStorage.getItem('lang') ? localStorage.getItem('lang'):'ru')

  constructor(private http:HttpClient) { }

  sendForm(value:form){
    return this.http.post(`https://IC-Savcapital.net/send-notification?name=${value.name}&phone=${value.phone}`, '')
  }
}


