import { Component } from '@angular/core';

@Component({
  selector: 'app-routing-block',
  templateUrl: './routing-block.component.html',
  styleUrls: ['./routing-block.component.scss']
})
export class RoutingBlockComponent {

}
