import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-see-also-funds',
  templateUrl: './see-also-funds.component.html',
  styleUrls: ['./see-also-funds.component.scss']
})
export class SeeAlsoFundsComponent {
  @Input() title1:string
  @Input() preTitle1:string
  @Input() preNum1:string
  @Input() Num1:string
  @Input() infoBottom1:string
  @Input() preNum2:string
  @Input() Num2:string
  @Input() infoBottom2:string
  @Input() preNum3:string
  @Input() Num3:string
  @Input() infoBottom3:string
  @Input() img1:string

  @Input() title2:string
  @Input() preTitle2:string
  @Input() preNum4:string
  @Input() Num4:string
  @Input() infoBottom4:string
  @Input() preNum5:string
  @Input() Num5:string
  @Input() infoBottom5:string
  @Input() preNum6:string
  @Input() Num6:string
  @Input() infoBottom6:string
  @Input() img2:string

  @Input() inRow:boolean = true
  @Input() bColor:string
}
