import { Component, OnInit } from '@angular/core';
import { animate, animation, state, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { FormService } from 'src/app/services/form.service';
import { FormDialogComponent } from '../form-dialog/form-dialog.component';
import * as langEn from './locale_en.json'
import * as langRu from './locale_ru.json'
import { Translate } from 'src/app/services/form.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger("menu", [
      state('start', style({ transform: 'translateY(-13rem)', visibility: 'hidden' })),
      state('end', style({ transform: 'translateY(0)', visibility: 'visible' })),
      // state('start', style({display: 'none'})),
      // state('end', style({display: 'block'})),

      transition('start <=> end', animate('.5s'))
    ]),
    trigger("dop-menu", [
      state('start', style({ transform: 'translateY(-100vh)', visibility: 'hidden' })),
      state('end', style({ transform: 'translateY(0)', visibility: 'visible' })),
      // state('start', style({display: 'none'})),
      // state('end', style({display: 'block'})),

      transition('start <=> end', animate('.2s'))
    ])
  ]
})
export class HeaderComponent implements OnInit {
  menu1 = false
  menuState1 = 'start'
  menu2 = false
  menuState2 = 'start'
  menu3 = false
  menuState3 = 'start'
  menu4 = false
  menuState4 = 'start'
  menu5 = false
  menuState5 = 'start'

  dopState = 'start'

  dopMenu1 = false
  dopMenu2 = false
  dopMenu3 = false
  dopMenu4 = false
  dopMenu5 = false
  dopMenu6 = false

  curLang = "en"
  en = langEn
  ru = langRu
  translates: Translate = {
    "en": this.en.en,
    "ru": this.ru.ru,
  }
  constructor(private formService: FormService, public dialog: MatDialog) {
    formService.curLang.subscribe(res => this.curLang = res!)
  }
  ngOnInit(): void {
    document.addEventListener('click', (e: any) => {
      if (this.menu1 === true || this.menu2 === true || this.menu3 === true || this.menu4 === true || this.menu5 === true) {
        let target = e.target
        let mainMenu = document.getElementById('mainMenu')
        let its_menu = target == mainMenu || mainMenu?.contains(target)
        if (!its_menu) {
          this.menu1 = false
          this.menuState1 = 'start'
          this.menu2 = false
          this.menuState2 = 'start'
          this.menu3 = false
          this.menuState3 = 'start'
          this.menu4 = false
          this.menuState4 = 'start'
          this.menu5 = false
          this.menuState5 = 'start'
        }
      }
    })
  }
  changeLang(event:any){
    this.formService.curLang.next(event.target.value)
    localStorage.setItem('lang', event.target.value)
  }

  openDialog() {
    const dialogRef = this.dialog.open(FormDialogComponent);
  }

  changeDop() {
    this.dopState = this.dopState === 'start' ? 'end' : 'start'
  }

  openDop1() {
    this.dopMenu1 = !this.dopMenu1
  }
  openDop2() {
    this.dopMenu2 = !this.dopMenu2
  }
  openDop3() {
    this.dopMenu3 = !this.dopMenu3
  }
  openDop4() {
    this.dopMenu4 = !this.dopMenu4
  }
  openDop5() {
    this.dopMenu5 = !this.dopMenu5
  }
  openDop6() {
    this.dopMenu6 = !this.dopMenu6
  }

  openMenu1() {
    this.menu2 = false
    this.menuState2 = 'start'
    this.menu3 = false
    this.menuState3 = 'start'
    this.menu4 = false
    this.menuState4 = 'start'
    this.menu5 = false
    this.menuState5 = 'start'

    this.menuState1 = this.menuState1 === 'start' ? 'end' : 'start'
    this.menu1 = this.menu1 === false ? true : false
  }
  openMenu2() {
    this.menu1 = false
    this.menuState1 = 'start'
    this.menu3 = false
    this.menuState3 = 'start'
    this.menu4 = false
    this.menuState4 = 'start'
    this.menu5 = false
    this.menuState5 = 'start'

    this.menuState2 = this.menuState2 === 'start' ? 'end' : 'start'
    this.menu2 = this.menu2 === false ? true : false
  }
  openMenu3() {
    this.menu2 = false
    this.menuState2 = 'start'
    this.menu1 = false
    this.menuState1 = 'start'
    this.menu4 = false
    this.menuState4 = 'start'
    this.menu5 = false
    this.menuState5 = 'start'

    this.menuState3 = this.menuState3 === 'start' ? 'end' : 'start'
    this.menu3 = this.menu3 === false ? true : false
  }
  openMenu4() {
    this.menu2 = false
    this.menuState2 = 'start'
    this.menu3 = false
    this.menuState3 = 'start'
    this.menu1 = false
    this.menuState1 = 'start'
    this.menu5 = false
    this.menuState5 = 'start'

    this.menuState4 = this.menuState4 === 'start' ? 'end' : 'start'
    this.menu4 = this.menu4 === false ? true : false
  }
  openMenu5() {
    this.menu2 = false
    this.menuState2 = 'start'
    this.menu3 = false
    this.menuState3 = 'start'
    this.menu4 = false
    this.menuState4 = 'start'
    this.menu1 = false
    this.menuState1 = 'start'

    this.menuState5 = this.menuState5 === 'start' ? 'end' : 'start'
    this.menu5 = this.menu5 === false ? true : false
  }

  pay() {
    window.open('https://workspace.investments-today.online/', '_blank') 
  }
  goTelegram(){
    window.open('https://t.me/IK_SAV_Capital','_blank') 
  }

  goWhatsApp(){
    window.open('https://wa.me/447958113861','_blank') 
  }
}
